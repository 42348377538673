export const TagCode = {
    TagUnknown       : 0, // 未知错误
    TagSuccess       : 1, // 成功
    TagFailed        : 2, // 失败
    TagNotPermission : 3, // 用户没有权限
    // 节点相关错误
    TagCanNotFindV2rayNodeInfo : 41, // 没有找到对应节点或者节点已下线
    // 下面是用户相关 101-199
    TagUserNotFind             : 101, // 用户找不到
    TagUserNotLogin            : 102, // 用户未登录
    TagUserTokenExpired        : 103, // token失效
    TagUserDisabled            : 104, // 用户被禁用
    TagUserNameOrPwdError      : 105, // 账号/密码错误
    TagUserLevelLower          : 106, // 用户等级不够
    TagUserNameRepeat          : 107, // 用户名重复
    TagUserPasswordValidFailed : 108, // 用户密码设置不符合要求
    TagDeviceTooMany           : 109, // 绑定账号太多
    TagDiamondToLower          : 110, // 钻石数量不足
    TagTelExists               : 111, // 电话号码已经存在
    TagAlreadyBound            : 112, // 玩家已经绑定上级
    TagInviteCodeError         : 113, // 邀请码错误，未找到对应用户
    TagTelNotRegistered        : 114, //手机号未注册
    TagCodeIntervalIsOneMinute : 115, //获取验证码的间隔时间为1分钟
    TagCodeIsError             : 116, //验证码错误
    TagNewUserSuccess          : 117, // 是新用户注册成功登录
    TagTypeIsError             : 118, // 类型错误
    TagAddressRepeat           : 119, // 付款地址已经存在
    TagSystemIsBusy            : 120, // 系统繁忙，请稍后再试！
    TagExceedingMaxConnect     : 121, // 超过节点最大连接数！
    TagCanNotRemoveDevice      : 122, // 设备正在使用中，无法删除
    TagSmsSendTimesOverflow    : 123, //每日获取短信只有10次
    TagAddressExists           : 124, // 支付地址已经存在
    TagOrderTooMany            : 125, // 今日订单创建次数已达上限
    TagAddressIsNull           : 126, // 支付地址为空
    TagAddressIsSame           : 127, // 当前有未支付的相同订单，无法生成新的订单
    TagPriceIsSame             : 128, // 不能使用该金额，容易导致支付出现问题
    TagPlanSettingNotFind      : 129, // 没找到对应套餐
    TagPlanSettingDisabled     : 130,// 套餐已禁用
    TagCoinLess: 131,               // 金币不足
    TagPlanSubscribeFailed: 132,    // 套餐订阅失败
    TagDeviceError: 133,            // 非法设备登录
    TagRequiredNotPattern:134,      // 不符合要求
    TagDeviceNotFound: 135,         // 设备信息错误
    TagPlanSubscribeErrorLevel:138, // 套餐到期之前只能兑换同能级套餐
    // 下面是服务相关 201-299
    TagUserSubscribeOverflow    : 201, // 流量溢出
    TagUserSubscribeTimeout     : 202, // 套餐已过期
    TagUserSvipSubscribeTimeout : 203, //SVIP套餐已过期
    // 下面是公告相关
    TagTitleRepeat   : 301, // 公告名称重复
    TagNoticeNotFind : 302, // 公告找不到
    // 下面是图片上传错误
    TagFileFormatIncorrect : 401, //文件格式不正确
    TagFileTooLarge        : 402, //文件太大
    TagFileCreateError     : 403, // 文件創建失敗
    TagFileNotFound        : 404, // 文件找不到
}

// 金币订单状态
export const OrderCoinStatus = {
    Create:1,
    Finished:9,
}
