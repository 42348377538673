import {createRouter,createWebHashHistory} from "vue-router";

import Login from "./page/Login.vue";

const router = createRouter({
    history:createWebHashHistory(),
    routes:[
        {
            path:"/",
            component:  () => import('./page/Splash.vue')
        },
        {
            path:"/login",
            component:Login
        },
        {
            path:"/home",
            component:  () => import('./page/Home.vue'),
            redirect:'/home/count',
            children: [   // 添加子路由
                {
                    path:"count",
                    name: 'count',
                    components: {   // 注意参数名带s
                        table: () => import('./page/Count.vue')
                    }
                },
                {
                    path:"user",
                    name: 'user',
                    components: {   // 注意参数名带s
                        table: () => import('./page/UserList.vue')
                    }
                },
                {
                    path:"order",
                    name: 'order',
                    components: {
                        table: () => import('./page/Order.vue')
                    }
                },
                {
                    path:"notice",
                    name: 'notice',
                    components: {
                        table: () => import('./page/Notice.vue')
                    }
                },
                {
                    path:"nodeList",
                    name: 'nodeList',
                    components: {
                        table: () => import('./page/NodeList.vue')
                    }
                },
                {
                    path:"plan",
                    name: 'plan',
                    components: {
                        table: () => import('./page/Plan.vue')
                    }
                },
                {
                    path:"smsCode",
                    name: 'smsCode',
                    components: {
                        table: () => import('./page/SmsRecord.vue')
                    }
                },
                {
                    path:"emailCode",
                    name: 'emailCode',
                    components: {
                        table: () => import('./page/EmailRecord.vue')
                    }
                },
                {
                    path:"feedback",
                    name: 'feedback',
                    components: {
                        table: () => import('./page/Feedback.vue')
                    }
                },
                {
                    path:"setting",
                    name:"setting",
                    components:{
                        table:()=>import("./page/Setting.vue")
                    }
                },
                {
                    path:"upload",
                    name:"upload",
                    components:{
                        table:()=>import("./page/UploadPicture.vue")
                    }
                }
                // ====================================================
                // 下面的暂时不需要
                // ====================================================
                // {
                //     path:"appSet",
                //     name: 'appSet',
                //     components: {
                //         table: () => import('./page/appSet.vue')
                //     }
                // },
                // {
                //     path:"authority", // 权限管理，这玩意儿压根就没有搞完，要它作甚？
                //     name: 'authority',
                //     components: {
                //         table: () => import('./page/authority.vue')
                //     }
                // },
                // {
                //     path:"shareSetting",
                //     name: 'shareSetting',
                //     components: {
                //         table: () => import('./page/shareSetting.vue')
                //     }
                // },
                // {
                //     path:"collectionAddress",
                //     name: 'collectionAddress',
                //     components: {
                //         table: () => import('./page/collectionAddress.vue')
                //     }
                // },
                // {
                //     path:"inviteList",
                //     name: 'inviteList',
                //     components: {
                //         table: () => import('./page/inviteList.vue')
                //     }
                // },
                // {
                //     path:"recharge",
                //     name: 'recharge',
                //     components: {
                //         table: () => import('./page/recharge.vue')
                //     }
                // },
            ]
        }
    ]
})

export default router;
