<template>
	<div class="pageWrap">

		<div class="loginBox">
      <div>
        <img src="../assets/images/2.png" alt="">
      </div>
      <div>
        <h2>CNX加速器</h2>
        <div  class="inputBox">
          <el-input
              v-model="username"
              placeholder="请输入账号"
              :prefix-icon="User"
              size="large"
          />
        </div>
        <div  class="inputBox">
          <el-input
              v-model="password"
              type="password"
              placeholder="请输入密码"
              :prefix-icon="Lock"
              size="large"
          />
        </div>
        <div class="loginBtn" @click="login">
          <el-button type="primary">登录</el-button>
        </div>
      </div>
		</div>
	</div>


</template>

<script setup>
import { User, Lock } from '@element-plus/icons-vue'
import {onMounted, ref} from "vue";
import {TagCode} from "@/const";
import {useRouter} from "vue-router";
import { ElLoading,ElMessage  } from 'element-plus'
import {Login} from "@/api";
import {DSLogin} from "@/protocol/dashboard_pb";
import {md5} from "js-md5";

const router = useRouter();
// 本机测试账号
const username = ref("");
const password = ref("");

if (process.env.NODE_ENV !== "production"){
  username.value = "I'mYourDaddy";
  password.value = "123456";
}

const login = ()=>{
  if (typeof username.value != "string" || username.value < 1){
    ElMessage.warning("账号不能为空")
    return
  }
  if (typeof password.value != "string" || password.value < 1){
    ElMessage.warning("密码不能为空")
    return
  }

	Login({
		Username:username.value,
		Password:md5(password.value),
		ValidateCode:"",// 验证码，暂时用不到
	}).then(resp=>{
		if (resp.Tag ===TagCode.TagSuccess){
			router.replace('/home');
		}else{
      ElMessage.error("登录失败");
		}
	}).catch(err=>{
		ElMessage.error("登录失败，请稍后重试！");
	})
}

</script>

<style lang="less" scoped>
.pageWrap{
	width:100%;
	height:100%;
	display: flex;
	justify-content: center;
	align-items:center;
	color:#333;
  background: linear-gradient(-135deg,#c850c0,#4158d0);

	.loginBox{
    width: 960px;
    height: 460px;
    background: #fff;
    border-radius: 10px;
		border: 1px solid #ccc;
		padding:30px 50px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 95px 33px;
	}
	h2{
    text-align: center;
		margin-bottom: 30px;
    background: linear-gradient(92.06deg,#33c2ff -17.9%,#1e6fff 43.39%,#1e6fff 99.4%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 28px;
    line-height: 1.25;
    font-weight: 500;
	}
	.inputBox{
		width:300px;
		margin-bottom:30px;
	}
	.loginBtn{
		width:100%;
    padding-top: 20px;
		.el-button{
			width:100%;
      height:40px;
      border-radius: 20px
		}
	}
}
</style>
<style>
.loginBox .el-input__wrapper{
  border-radius: 20px
}
</style>
