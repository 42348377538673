// source: enum.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.protocol.ServerType', null, global);
goog.exportSymbol('proto.protocol.StreamId', null, global);
goog.exportSymbol('proto.protocol.TagCode', null, global);
/**
 * @enum {number}
 */
proto.protocol.ServerType = {
  SERVERUNKNOWN: 0,
  SERVERV2RAY: 1,
  SERVERCLIENT: 2,
  SERVERDASHBOARD: 3
};

/**
 * @enum {number}
 */
proto.protocol.TagCode = {
  TAGUNKNOWN: 0,
  TAGSUCCESS: 1,
  TAGFAILED: 2,
  TAGNOTPERMISSION: 3,
  TAGNOTIMPLEMENTED: 4,
  TAGINMAINTENANCE: 14,
  TAGCANNOTFINDV2RAYNODEINFO: 41,
  TAGUSERNOTFIND: 101,
  TAGUSERNOTLOGIN: 102,
  TAGUSERTOKENEXPIRED: 103,
  TAGUSERDISABLED: 104,
  TAGUSERNAMEORPWDERROR: 105,
  TAGUSERLEVELLOWER: 106,
  TAGUSERNAMEREPEAT: 107,
  TAGUSERPASSWORDVALIDFAILED: 108,
  TAGDEVICETOOMANY: 109,
  TAGDIAMONDTOLOWER: 110,
  TAGTELEXISTS: 111,
  TAGALREADYBOUND: 112,
  TAGINVITECODEERROR: 113,
  TAGTELNOTREGISTERED: 114,
  TAGCODEINTERVALISONEMINUTE: 115,
  TAGCODEISERROR: 116,
  TAGNEWUSERSUCCESS: 117,
  TAGTYPEISERROR: 118,
  TAGADDRESSREPEAT: 119,
  TAGSYSTEMISBUSY: 120,
  TAGEXCEEDINGMAXCONNECT: 121,
  TAGCANNOTREMOVEDEVICE: 122,
  TAGSMSSENDTIMESOVERFLOW: 123,
  TAGADDRESSEXISTS: 124,
  TAGORDERTOOMANY: 125,
  TAGADDRESSISNULL: 126,
  TAGADDRESSISSAME: 127,
  TAGPRICEISSAME: 128,
  TAGPLANSETTINGNOTFIND: 129,
  TAGPLANSETTINGDISABLED: 130,
  TAGCOINLESS: 131,
  TAGPLANSUBSCRIBEFAILED: 132,
  TAGDEVICEERROR: 133,
  TAGREQUIREDNOTPATTERN: 134,
  TAGDEVICENOTFOUND: 135,
  TAGDEVICENUMOVERFLOW: 136,
  TAGFREEPLANISDISPATCHER: 137,
  TAGPLANSUBSCRIBEERRORLEVEL: 138,
  TAGCOINORDERLOCK: 181,
  TAGCOINORDEREXPIRE: 182,
  TAGUSERSUBSCRIBEOVERFLOW: 201,
  TAGUSERSUBSCRIBETIMEOUT: 202,
  TAGUSERSVIPSUBSCRIBETIMEOUT: 203,
  TAGTITLEREPEAT: 301,
  TAGNOTICENOTFIND: 302,
  TAGFILEFORMATINCORRECT: 401,
  TAGFILETOOLARGE: 402,
  TAGFILECREATEERROR: 403,
  TAGFILENOTFOUND: 404,
  TAGVERSIONNEEDUPDATE: 901,
  TAGVERSIONFORCEUPDATE: 902
};

/**
 * @enum {number}
 */
proto.protocol.StreamId = {
  STREAMUNKNOWN: 0,
  V2RAYSERVERINFO: 1,
  V2RAYSERVERSYSTEMINFO: 2,
  V2RAYSERVERSTART: 3,
  V2RAYSERVERSTOP: 4,
  V2RAYPING: 11,
  V2RAYREPORTUSERCOUNTER: 50,
  V2RAYREPORTUSERLINKDURATION: 51,
  V2RAYINBOUNDCLIENTADD: 101,
  V2RAYINBOUNDCLIENTQUERY: 102,
  V2RAYINBOUNDCLIENTEDIT: 103,
  V2RAYINBOUNDCLIENTDELETE: 104
};

goog.object.extend(exports, proto.protocol);
