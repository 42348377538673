import {
    DSCoinPlanEdit,
    DSCountDay, DSDeviceRemove,
    DSEditSetting,
    DSEditUser, DSGetV2rayServerList,
    DSLogin,
    DSLogout, DSNoticeInfo, DSPlanAdd, DSPlanEdit, DSQaInfo, DSQuerySetting,
    DSSendCoin, DSSetUserPlan,
    DSTokenValid, DSUpdateUserStatus, DSUpdateV2rayNodeInfo, DSQuerySimplePlanList, DSUpdateUploadPictureStatus,
} from "@/protocol/dashboard_pb";
import axios from "axios";

import {CoinPlanSetting, PaginateRequest} from "@/protocol/common_pb";

const ApiHost = process.env.NODE_ENV === "production" ? "/grpc" : "https://localhost/grpc";

const pbNormalFilterKeys = ['CreatedAt','UpdatedAt'];

import router from "@/router";
import {TagCode} from "@/const";

export const ProtoEncode = (obj, cls,filterKeys = undefined) => {
    if (process.env.NODE_ENV !== "production"){
        console.log(obj,filterKeys);
    }
    if (!filterKeys){
        filterKeys = [...pbNormalFilterKeys];
    }else{
        filterKeys = [...filterKeys,...pbNormalFilterKeys];
    }
    let ds = new cls();
    if (obj) {
        for (let key in obj) {
            if (filterKeys){
                let idx = filterKeys.findIndex(k=>key===k);
                process.env.NODE_ENV !== "production" && console.log("filterKeys",key,idx);
                if (idx !== -1){
                    continue;
                }

            }
            if (typeof key == "string" && key.length > 0){
                let funcKey = key.substring(0,1).toUpperCase() + key.substring(1,key.length).toLowerCase();
                if (Array.isArray(obj[key])){
                    funcKey += 'List';
                }
                if (typeof ds[`set${funcKey}`] == "function" && obj[key]) {
                    process.env.NODE_ENV !== "production" && console.log("protobuf", `set${funcKey}`, obj[key]);
                    ds[`set${funcKey}`](obj[key]);
                }else{
                    process.env.NODE_ENV !== "production" && console.log("protobuf", `set${funcKey}`, "not function",ds);
                }
            }
        }
    }
    return ds;
};

/**
 * @param {object} obj
 * @param {class} cls
 * @param {Array?}filterKeys
 * @return {Uint8Array}
 */
const encode = (obj, cls,filterKeys = undefined) => {
    let ds = ProtoEncode(obj,cls,filterKeys);
    return ds.serializeBinary();
}

/**
 * 发送请求,请求protobuf返回json
 * @param {string} api
 * @param {Uint8Array} pb
 */
const send = (api, pb) => {
    return new Promise((resolve, reject) => {
        if (!api.startsWith("/")) {
            api = `/${api}`
        }
        console.log(ApiHost + api)
        // fetch(ApiHost + api,{
        //     method: 'POST',
        //     mode: 'cors',
        //     cache: 'no-cache',
        //     credentials: 'same-origin',
        //     headers: {
        //         // 'Content-Type': 'application/json'
        //     },
        //     redirect: 'follow',
        //     referrerPolicy: 'no-referrer',
        //     body: JSON.stringify(pb)
        // }).then(resp=>{
        //     console.log(resp);
        // }).catch(err=>{
        //     console.log(err);
        // })
        axios.post(ApiHost + api, pb, {
            withCredentials: true,
        }).then(resp => {
            if (resp.status === 200) {
                console.log(resp.data);
                if (resp.data.Tag === TagCode.TagUserTokenExpired){
                    router.push("/login");
                    return
                }
                // let sd = sdCls.deserializeBinary(resp.data);
                // resolve(sd.toObject());
                resolve(resp.data);
            } else {
                reject(new Error(`request failed status:${resp.status}`));
            }
        }).catch(reject)
    })
}
/**
 * @param {string} api
 * @param {object} obj 提交得数据对象
 * @param {class} dsCls DS协议类
 * @return Promise<Message>
 */
const request = (api, obj, dsCls) => {
    return send(api, encode(obj, dsCls))
}

/**
 * 校验token
 * @param {object} obj
 * @return Promise<Message>
 * @constructor
 */
export const TokenValid = (obj) => {
    return request("/TokenValid", obj, DSTokenValid);
}

/**
 * 登录
 * @param obj
 * @return {Promise<Message>}
 * @constructor
 */
export const Login = (obj) => {
    return request("/Login", obj, DSLogin);
}

/**
 * 退出登录
 * @param obj
 * @return {Promise<Message>}
 * @constructor
 */
export const Logout = (obj) => {
    return request("/Logout", obj, DSLogout);
}

/**
 * 查询用户列表
 * @param obj
 * @return {Promise<Message>}
 * @constructor
 */
export const QueryUserPaginate = (obj) => {
    return request("/QueryUserPaginate", obj, PaginateRequest);
}

/**
 * 修改用户信息
 * @param obj
 * @return {Promise<Message>}
 * @constructor
 */
export const EditUser = (obj) => {
    return request("/EditUser", obj, DSEditUser);
}

/**
 * 发送金币
 * @param obj
 * @return {Promise<Message>}
 * @constructor
 */
export const SendCoin = (obj) => {
    return request("/SendCoin", obj, DSSendCoin);
}

/**
 * 为用户设置套餐
 * @param obj
 * @return {Promise<Message>}
 * @constructor
 */
export const SetUserPlan = (obj) => {
    return request("/SetUserPlan", obj, DSSetUserPlan);
}

/**
 * 用户的停用/启用，这个其实作用不大
 * @param obj
 * @return {Promise<Message>}
 * @constructor
 */
export const UpdateUserStatus = (obj) => {
    return request("/UpdateUserStatus", obj, DSUpdateUserStatus);
}

/**
 * 查询金币订单
 * @param obj
 * @return {Promise<Message>}
 * @constructor
 */
export const QueryOrderCoinPaginate = (obj) => {
    return request("/QueryOrderCoinPaginate", obj, PaginateRequest);
}

/**
 * 查询套餐订单
 * @param obj
 * @return {Promise<Message>}
 * @constructor
 */
export const QueryOrderPlanPaginate = (obj) => {
    return request("/QueryOrderPlanPaginate", obj, PaginateRequest);
}

/**
 * 查询公告列表
 * @param obj
 * @return {Promise<Message>}
 * @constructor
 */
export const QueryNoticePaginate = (obj) => {
    return request("/QueryNoticePaginate", obj, PaginateRequest);
}

/**
 * 删除公告
 * @param obj
 * @return {Promise<Message>}
 * @constructor
 */
export const DelNotice = (obj) => {
    return request("/DelNotice", obj, DSNoticeInfo);
}

/**
 * 新增公告
 * @param obj
 * @return {Promise<Message>}
 * @constructor
 */
export const AddNotice = (obj) => {
    return request("/AddNotice", obj, DSNoticeInfo);
}

/**
 * 更新公告
 * @param obj
 * @return {Promise<Message>}
 * @constructor
 */
export const UpdNotice = (obj) => {
    return request("/UpdNotice", obj, DSNoticeInfo);
}

/**
 * 获取节点列表，这个结构体有待修改啊
 * @param obj
 * @return {Promise<Message>}
 * @constructor
 */
export const GetV2RayServerList = (obj) => {
    return request("/GetV2RayServerList", obj, DSGetV2rayServerList);
}

/**
 * 更新v2ray节点信息
 * @param obj
 * @return {Promise<Message>}
 * @constructor
 */
export const UpdateV2RayNodeInfo = (obj) => {
    return request("/UpdateV2RayNodeInfo", obj, DSUpdateV2rayNodeInfo);
}
/**
 * 新增套餐
 * @param obj
 * @return {Promise<Message>}
 * @constructor
 */
export const AddPlan = (obj) => {
    return request("/AddPlan", obj, DSPlanAdd);
}

/**
 * 编辑套餐
 * @param obj
 * @return {Promise<Message>}
 * @constructor
 */
export const EditPlan = (obj) => {
    return request("/EditPlan", obj, DSPlanEdit);
}

/**
 * 查询套餐列表
 * @param obj
 * @return {Promise<Message>}
 * @constructor
 */
export const QueryPlanPaginate = (obj) => {
    return request("/QueryPlanPaginate", obj, PaginateRequest);
}

/**
 * 查询短信记录
 * @param obj
 * @return {Promise<Message>}
 * @constructor
 */
export const QuerySMSRecordPaginate = (obj) => {
    return request("/QuerySMSRecordPaginate", obj, PaginateRequest);
}

/**
 * 查询邮件发送记录
 * @param obj
 * @return {Promise<Message>}
 * @constructor
 */
export const QueryEmailRecordPaginate = (obj) => {
    return request("/QueryEmailRecordPaginate", obj, PaginateRequest);
}

/**
 * 查询反馈列表
 * @param obj
 * @return {Promise<Message>}
 * @constructor
 */
export const QueryQaPaginate = (obj) => {
    return request("/QueryQaPaginate", obj, PaginateRequest);
}

/**
 * 更新反馈信息
 * @param obj
 * @return {Promise<Message>}
 * @constructor
 */
export const UpdQaInfo = (obj) => {
    return request("/UpdQaInfo", obj, DSQaInfo);
}

/**
 * 查询全局配置
 * @param obj
 * @return {Promise<Message>}
 * @constructor
 */
export const QuerySetting = (obj) => {
    return request("/QuerySetting", obj, DSQuerySetting);
}

/**
 * 编辑全局配置
 * @param obj
 * @return {Promise<Message>}
 * @constructor
 */
export const EditSetting = (obj) => {
    return request("/EditSetting", obj, DSEditSetting);
}

/**
 * 收页统计数据
 * @param obj
 * @return {Promise<Message>}
 * @constructor
 */
export const QueryCountDay = (obj)=>{
    return request("/QueryCountDay",obj,DSCountDay);
}

/**
 * 查询金币套餐配置列表
 * @param obj
 * @return {Promise<Message>}
 * @constructor
 */
export const QueryCoinPlanSettingPaginate = (obj) => {
    return request("/QueryCoinPlanPaginate",obj,PaginateRequest);
}

/**
 * 新增金币套餐
 * @param obj
 * @constructor
 */
export const AddCoinPlanSetting = (obj) => {
    // 小数转整数
    let fObj = Object.assign({...obj})
    return request("/AddCoinPlan",{
        Setting:ProtoEncode(obj,CoinPlanSetting),
    },DSCoinPlanEdit);
}
/**
 * 编辑金币套餐
 * @param obj
 * @return {Promise<Message>}
 * @constructor
 */
export const EditCoinPlanSetting = (obj) => {
    // 小数转整数
    let fObj = Object.assign({...obj})
    return request("/EditCoinPlan",{
        Setting:ProtoEncode(fObj,CoinPlanSetting),
    },DSCoinPlanEdit);
}
/**
 * 删除金币套餐
 * @param obj
 * @return {Promise<Message>}
 * @constructor
 */
export const DeleteCoinPlanSetting = (obj) => {
    return request("/DeleteCoinPlan",{
        Setting:ProtoEncode(obj,CoinPlanSetting),
    },DSCoinPlanEdit);
}

/**
 * 删除设备信息
 * @param obj
 * @return {Promise<Message>}
 * @constructor
 */
export const DeviceRemove = (obj) => {
    return request("/DeviceRemove",obj,DSDeviceRemove);
}

export const QuerySimplePlanList = (obj) => {
    return request("/QuerySimplePlanList",obj,DSQuerySimplePlanList);
}

/**
 * 分页获取图片列表
 * @param obj
 * @return {Promise<Message>}
 * @constructor
 */
export const QueryUploadPicture = (obj) => {
    return request("/QueryUploadPicture",obj,PaginateRequest);
}

/**
 *
 * @param {{id:number,status:number}} obj
 * @return {Promise<Message>}
 * @constructor
 */
export const UpdateUploadPictureStatus = (obj)=>{
    return request("/UpdateUploadPictureStatus",obj,DSUpdateUploadPictureStatus);
}

export const UploadPictureUrl = (name)=>{
    return ApiHost+"/upload/"+name;
}
