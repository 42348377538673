<script setup>
import {onBeforeMount, onMounted} from "vue";
import {TokenValid} from "@/api";
import {TagCode} from "@/const";
import {useRouter} from "vue-router";
const router = useRouter();

onBeforeMount(()=>{
  // let token = Cookies.get('AUTH_TOKEN');
  // console.log('cookie',token);
  // if (typeof token == "string" && token.length > 0){

  // }else{
  //   router.push('/login');
  // }
})

onMounted(()=>{
  TokenValid({}).then(resp=>{
    console.log(resp);
    if (resp.Tag === TagCode.TagSuccess){
      if (window.location.href.endsWith("#/")){
        router.push("/home");
      }
      console.log(router,router.currentRoute.value.path);
      // // token校验成功，可以直接进首页
      // if (router.currentRoute.value.path === "/"){
      //   router.push("/home");
      // }else{
      //   router.push(router.currentRoute.value.path);
      // }
    }else{
      // token校验失败，还是要重回登录
      router.push('/login');
    }
  }).catch(err=>{
    router.push('/login');
    console.log(err)
    // serverErr.value = true
  })
})

// ========================================================================
// 解决ERROR ResizeObserver loop completed with undelivered notifications.
//问题的

const debounce = (fn, delay) => {

  let timer = null;

  return function () {

    let context = this;

    let args = arguments;

    clearTimeout(timer);

    timer = setTimeout(function () {

      fn.apply(context, args);

    }, delay);

  }

}

// 解决ERROR ResizeObserver loop completed with undelivered notifications.

//问题的

const _ResizeObserver = window.ResizeObserver;

window.ResizeObserver = class ResizeObserver extends _ResizeObserver {

  constructor(callback) {

    callback = debounce(callback, 16);

    super(callback);

  }

}

// ========================================================================
</script>

<template>
  <router-view></router-view>
</template>

<style>
</style>
