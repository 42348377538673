// source: common.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_any_pb = require('google-protobuf/google/protobuf/any_pb.js');
goog.object.extend(proto, google_protobuf_any_pb);
var enum_pb = require('./enum_pb.js');
goog.object.extend(proto, enum_pb);
goog.exportSymbol('proto.protocol.AppVersion', null, global);
goog.exportSymbol('proto.protocol.CoinPlanSetting', null, global);
goog.exportSymbol('proto.protocol.DeviceInfo', null, global);
goog.exportSymbol('proto.protocol.DiamondRecordInfo', null, global);
goog.exportSymbol('proto.protocol.InboundClient', null, global);
goog.exportSymbol('proto.protocol.InviteConfigInfo', null, global);
goog.exportSymbol('proto.protocol.InviteRecordInfo', null, global);
goog.exportSymbol('proto.protocol.LoginRecordInfo', null, global);
goog.exportSymbol('proto.protocol.MethodRoleInfo', null, global);
goog.exportSymbol('proto.protocol.NoticeInfo', null, global);
goog.exportSymbol('proto.protocol.OrderCoin', null, global);
goog.exportSymbol('proto.protocol.OrderInfo', null, global);
goog.exportSymbol('proto.protocol.OrderPlan', null, global);
goog.exportSymbol('proto.protocol.PaginateRequest', null, global);
goog.exportSymbol('proto.protocol.PaginateResponse', null, global);
goog.exportSymbol('proto.protocol.PayChannel', null, global);
goog.exportSymbol('proto.protocol.PlanCycle', null, global);
goog.exportSymbol('proto.protocol.PlanSetting', null, global);
goog.exportSymbol('proto.protocol.PlanUser', null, global);
goog.exportSymbol('proto.protocol.QAImage', null, global);
goog.exportSymbol('proto.protocol.QuestionAnswer', null, global);
goog.exportSymbol('proto.protocol.ReceiveConfigInfo', null, global);
goog.exportSymbol('proto.protocol.RechargeRecordInfo', null, global);
goog.exportSymbol('proto.protocol.RecordInfo', null, global);
goog.exportSymbol('proto.protocol.Setting', null, global);
goog.exportSymbol('proto.protocol.SmsRecordInfo', null, global);
goog.exportSymbol('proto.protocol.TagError', null, global);
goog.exportSymbol('proto.protocol.UploadPicture', null, global);
goog.exportSymbol('proto.protocol.UploadPictureSetting', null, global);
goog.exportSymbol('proto.protocol.UserInfo', null, global);
goog.exportSymbol('proto.protocol.V2rayNodeCustomInfo', null, global);
goog.exportSymbol('proto.protocol.V2rayNodeInfo', null, global);
goog.exportSymbol('proto.protocol.VemssInfo', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protocol.InboundClient = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protocol.InboundClient, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protocol.InboundClient.displayName = 'proto.protocol.InboundClient';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protocol.V2rayNodeCustomInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protocol.V2rayNodeCustomInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protocol.V2rayNodeCustomInfo.displayName = 'proto.protocol.V2rayNodeCustomInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protocol.V2rayNodeInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protocol.V2rayNodeInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protocol.V2rayNodeInfo.displayName = 'proto.protocol.V2rayNodeInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protocol.DeviceInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protocol.DeviceInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protocol.DeviceInfo.displayName = 'proto.protocol.DeviceInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protocol.UploadPictureSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protocol.UploadPictureSetting.repeatedFields_, null);
};
goog.inherits(proto.protocol.UploadPictureSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protocol.UploadPictureSetting.displayName = 'proto.protocol.UploadPictureSetting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protocol.UserInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protocol.UserInfo.repeatedFields_, null);
};
goog.inherits(proto.protocol.UserInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protocol.UserInfo.displayName = 'proto.protocol.UserInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protocol.NoticeInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protocol.NoticeInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protocol.NoticeInfo.displayName = 'proto.protocol.NoticeInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protocol.QuestionAnswer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protocol.QuestionAnswer.repeatedFields_, null);
};
goog.inherits(proto.protocol.QuestionAnswer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protocol.QuestionAnswer.displayName = 'proto.protocol.QuestionAnswer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protocol.ReceiveConfigInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protocol.ReceiveConfigInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protocol.ReceiveConfigInfo.displayName = 'proto.protocol.ReceiveConfigInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protocol.MethodRoleInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protocol.MethodRoleInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protocol.MethodRoleInfo.displayName = 'proto.protocol.MethodRoleInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protocol.RecordInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protocol.RecordInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protocol.RecordInfo.displayName = 'proto.protocol.RecordInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protocol.LoginRecordInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protocol.LoginRecordInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protocol.LoginRecordInfo.displayName = 'proto.protocol.LoginRecordInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protocol.OrderInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protocol.OrderInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protocol.OrderInfo.displayName = 'proto.protocol.OrderInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protocol.DiamondRecordInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protocol.DiamondRecordInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protocol.DiamondRecordInfo.displayName = 'proto.protocol.DiamondRecordInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protocol.InviteRecordInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protocol.InviteRecordInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protocol.InviteRecordInfo.displayName = 'proto.protocol.InviteRecordInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protocol.SmsRecordInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protocol.SmsRecordInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protocol.SmsRecordInfo.displayName = 'proto.protocol.SmsRecordInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protocol.RechargeRecordInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protocol.RechargeRecordInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protocol.RechargeRecordInfo.displayName = 'proto.protocol.RechargeRecordInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protocol.PaginateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protocol.PaginateRequest.repeatedFields_, null);
};
goog.inherits(proto.protocol.PaginateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protocol.PaginateRequest.displayName = 'proto.protocol.PaginateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protocol.PaginateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.protocol.PaginateResponse.repeatedFields_, null);
};
goog.inherits(proto.protocol.PaginateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protocol.PaginateResponse.displayName = 'proto.protocol.PaginateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protocol.TagError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protocol.TagError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protocol.TagError.displayName = 'proto.protocol.TagError';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protocol.VemssInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protocol.VemssInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protocol.VemssInfo.displayName = 'proto.protocol.VemssInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protocol.QAImage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protocol.QAImage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protocol.QAImage.displayName = 'proto.protocol.QAImage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protocol.InviteConfigInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protocol.InviteConfigInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protocol.InviteConfigInfo.displayName = 'proto.protocol.InviteConfigInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protocol.PlanSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protocol.PlanSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protocol.PlanSetting.displayName = 'proto.protocol.PlanSetting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protocol.CoinPlanSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protocol.CoinPlanSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protocol.CoinPlanSetting.displayName = 'proto.protocol.CoinPlanSetting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protocol.PlanCycle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protocol.PlanCycle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protocol.PlanCycle.displayName = 'proto.protocol.PlanCycle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protocol.PlanUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protocol.PlanUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protocol.PlanUser.displayName = 'proto.protocol.PlanUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protocol.AppVersion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protocol.AppVersion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protocol.AppVersion.displayName = 'proto.protocol.AppVersion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protocol.Setting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protocol.Setting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protocol.Setting.displayName = 'proto.protocol.Setting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protocol.OrderCoin = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protocol.OrderCoin, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protocol.OrderCoin.displayName = 'proto.protocol.OrderCoin';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protocol.OrderPlan = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protocol.OrderPlan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protocol.OrderPlan.displayName = 'proto.protocol.OrderPlan';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protocol.PayChannel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protocol.PayChannel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protocol.PayChannel.displayName = 'proto.protocol.PayChannel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.protocol.UploadPicture = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.protocol.UploadPicture, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.protocol.UploadPicture.displayName = 'proto.protocol.UploadPicture';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protocol.InboundClient.prototype.toObject = function(opt_includeInstance) {
  return proto.protocol.InboundClient.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protocol.InboundClient} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.InboundClient.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    upspeedlimit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    downspeedlimit: jspb.Message.getFieldWithDefault(msg, 3, 0),
    timeout: jspb.Message.getFieldWithDefault(msg, 4, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 5, 0),
    planendtime: (f = msg.getPlanendtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protocol.InboundClient}
 */
proto.protocol.InboundClient.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protocol.InboundClient;
  return proto.protocol.InboundClient.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protocol.InboundClient} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protocol.InboundClient}
 */
proto.protocol.InboundClient.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUpspeedlimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDownspeedlimit(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTimeout(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserid(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPlanendtime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protocol.InboundClient.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protocol.InboundClient.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protocol.InboundClient} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.InboundClient.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUpspeedlimit();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getDownspeedlimit();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getTimeout();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getPlanendtime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ClientId = 1;
 * @return {string}
 */
proto.protocol.InboundClient.prototype.getClientid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.InboundClient} returns this
 */
proto.protocol.InboundClient.prototype.setClientid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 UpSpeedLimit = 2;
 * @return {number}
 */
proto.protocol.InboundClient.prototype.getUpspeedlimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.InboundClient} returns this
 */
proto.protocol.InboundClient.prototype.setUpspeedlimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 DownSpeedLimit = 3;
 * @return {number}
 */
proto.protocol.InboundClient.prototype.getDownspeedlimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.InboundClient} returns this
 */
proto.protocol.InboundClient.prototype.setDownspeedlimit = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 Timeout = 4;
 * @return {number}
 */
proto.protocol.InboundClient.prototype.getTimeout = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.InboundClient} returns this
 */
proto.protocol.InboundClient.prototype.setTimeout = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 UserId = 5;
 * @return {number}
 */
proto.protocol.InboundClient.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.InboundClient} returns this
 */
proto.protocol.InboundClient.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp PlanEndTime = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protocol.InboundClient.prototype.getPlanendtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protocol.InboundClient} returns this
*/
proto.protocol.InboundClient.prototype.setPlanendtime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protocol.InboundClient} returns this
 */
proto.protocol.InboundClient.prototype.clearPlanendtime = function() {
  return this.setPlanendtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protocol.InboundClient.prototype.hasPlanendtime = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protocol.V2rayNodeCustomInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.protocol.V2rayNodeCustomInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protocol.V2rayNodeCustomInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.V2rayNodeCustomInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    servername: jspb.Message.getFieldWithDefault(msg, 1, ""),
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    level: jspb.Message.getFieldWithDefault(msg, 3, 0),
    sort: jspb.Message.getFieldWithDefault(msg, 4, 0),
    visible: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    icon: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protocol.V2rayNodeCustomInfo}
 */
proto.protocol.V2rayNodeCustomInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protocol.V2rayNodeCustomInfo;
  return proto.protocol.V2rayNodeCustomInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protocol.V2rayNodeCustomInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protocol.V2rayNodeCustomInfo}
 */
proto.protocol.V2rayNodeCustomInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setServername(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLevel(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSort(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVisible(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protocol.V2rayNodeCustomInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protocol.V2rayNodeCustomInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protocol.V2rayNodeCustomInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.V2rayNodeCustomInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getLevel();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getSort();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getVisible();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string ServerName = 1;
 * @return {string}
 */
proto.protocol.V2rayNodeCustomInfo.prototype.getServername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.V2rayNodeCustomInfo} returns this
 */
proto.protocol.V2rayNodeCustomInfo.prototype.setServername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool Enabled = 2;
 * @return {boolean}
 */
proto.protocol.V2rayNodeCustomInfo.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protocol.V2rayNodeCustomInfo} returns this
 */
proto.protocol.V2rayNodeCustomInfo.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional int32 Level = 3;
 * @return {number}
 */
proto.protocol.V2rayNodeCustomInfo.prototype.getLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.V2rayNodeCustomInfo} returns this
 */
proto.protocol.V2rayNodeCustomInfo.prototype.setLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 Sort = 4;
 * @return {number}
 */
proto.protocol.V2rayNodeCustomInfo.prototype.getSort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.V2rayNodeCustomInfo} returns this
 */
proto.protocol.V2rayNodeCustomInfo.prototype.setSort = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool Visible = 5;
 * @return {boolean}
 */
proto.protocol.V2rayNodeCustomInfo.prototype.getVisible = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protocol.V2rayNodeCustomInfo} returns this
 */
proto.protocol.V2rayNodeCustomInfo.prototype.setVisible = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string Icon = 6;
 * @return {string}
 */
proto.protocol.V2rayNodeCustomInfo.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.V2rayNodeCustomInfo} returns this
 */
proto.protocol.V2rayNodeCustomInfo.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protocol.V2rayNodeInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.protocol.V2rayNodeInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protocol.V2rayNodeInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.V2rayNodeInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    serverid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    servername: jspb.Message.getFieldWithDefault(msg, 2, ""),
    domain: jspb.Message.getFieldWithDefault(msg, 3, ""),
    port: jspb.Message.getFieldWithDefault(msg, 4, 0),
    clientid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    serverlevel: jspb.Message.getFieldWithDefault(msg, 6, 0),
    custominfo: (f = msg.getCustominfo()) && proto.protocol.V2rayNodeCustomInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protocol.V2rayNodeInfo}
 */
proto.protocol.V2rayNodeInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protocol.V2rayNodeInfo;
  return proto.protocol.V2rayNodeInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protocol.V2rayNodeInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protocol.V2rayNodeInfo}
 */
proto.protocol.V2rayNodeInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setServerid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setServername(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomain(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPort(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientid(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setServerlevel(value);
      break;
    case 7:
      var value = new proto.protocol.V2rayNodeCustomInfo;
      reader.readMessage(value,proto.protocol.V2rayNodeCustomInfo.deserializeBinaryFromReader);
      msg.setCustominfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protocol.V2rayNodeInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protocol.V2rayNodeInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protocol.V2rayNodeInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.V2rayNodeInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServerid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getServername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDomain();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPort();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getClientid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getServerlevel();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getCustominfo();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.protocol.V2rayNodeCustomInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 ServerId = 1;
 * @return {number}
 */
proto.protocol.V2rayNodeInfo.prototype.getServerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.V2rayNodeInfo} returns this
 */
proto.protocol.V2rayNodeInfo.prototype.setServerid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string ServerName = 2;
 * @return {string}
 */
proto.protocol.V2rayNodeInfo.prototype.getServername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.V2rayNodeInfo} returns this
 */
proto.protocol.V2rayNodeInfo.prototype.setServername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Domain = 3;
 * @return {string}
 */
proto.protocol.V2rayNodeInfo.prototype.getDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.V2rayNodeInfo} returns this
 */
proto.protocol.V2rayNodeInfo.prototype.setDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 Port = 4;
 * @return {number}
 */
proto.protocol.V2rayNodeInfo.prototype.getPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.V2rayNodeInfo} returns this
 */
proto.protocol.V2rayNodeInfo.prototype.setPort = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string ClientId = 5;
 * @return {string}
 */
proto.protocol.V2rayNodeInfo.prototype.getClientid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.V2rayNodeInfo} returns this
 */
proto.protocol.V2rayNodeInfo.prototype.setClientid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 ServerLevel = 6;
 * @return {number}
 */
proto.protocol.V2rayNodeInfo.prototype.getServerlevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.V2rayNodeInfo} returns this
 */
proto.protocol.V2rayNodeInfo.prototype.setServerlevel = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional V2rayNodeCustomInfo CustomInfo = 7;
 * @return {?proto.protocol.V2rayNodeCustomInfo}
 */
proto.protocol.V2rayNodeInfo.prototype.getCustominfo = function() {
  return /** @type{?proto.protocol.V2rayNodeCustomInfo} */ (
    jspb.Message.getWrapperField(this, proto.protocol.V2rayNodeCustomInfo, 7));
};


/**
 * @param {?proto.protocol.V2rayNodeCustomInfo|undefined} value
 * @return {!proto.protocol.V2rayNodeInfo} returns this
*/
proto.protocol.V2rayNodeInfo.prototype.setCustominfo = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protocol.V2rayNodeInfo} returns this
 */
proto.protocol.V2rayNodeInfo.prototype.clearCustominfo = function() {
  return this.setCustominfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protocol.V2rayNodeInfo.prototype.hasCustominfo = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protocol.DeviceInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.protocol.DeviceInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protocol.DeviceInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.DeviceInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    devicemode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    os: jspb.Message.getFieldWithDefault(msg, 3, ""),
    systemversion: jspb.Message.getFieldWithDefault(msg, 4, ""),
    version: jspb.Message.getFieldWithDefault(msg, 5, ""),
    uniqueid: jspb.Message.getFieldWithDefault(msg, 6, ""),
    status: jspb.Message.getFieldWithDefault(msg, 7, 0),
    iscurrent: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    id: jspb.Message.getFieldWithDefault(msg, 9, 0),
    lastlogintime: (f = msg.getLastlogintime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    versioncode: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protocol.DeviceInfo}
 */
proto.protocol.DeviceInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protocol.DeviceInfo;
  return proto.protocol.DeviceInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protocol.DeviceInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protocol.DeviceInfo}
 */
proto.protocol.DeviceInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDevicemode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOs(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemversion(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUniqueid(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIscurrent(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setId(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastlogintime(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVersioncode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protocol.DeviceInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protocol.DeviceInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protocol.DeviceInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.DeviceInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDevicemode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOs();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSystemversion();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUniqueid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getIscurrent();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeUint64(
      9,
      f
    );
  }
  f = message.getLastlogintime();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getVersioncode();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
};


/**
 * optional string DeviceId = 1;
 * @return {string}
 */
proto.protocol.DeviceInfo.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.DeviceInfo} returns this
 */
proto.protocol.DeviceInfo.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string DeviceMode = 2;
 * @return {string}
 */
proto.protocol.DeviceInfo.prototype.getDevicemode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.DeviceInfo} returns this
 */
proto.protocol.DeviceInfo.prototype.setDevicemode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Os = 3;
 * @return {string}
 */
proto.protocol.DeviceInfo.prototype.getOs = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.DeviceInfo} returns this
 */
proto.protocol.DeviceInfo.prototype.setOs = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string SystemVersion = 4;
 * @return {string}
 */
proto.protocol.DeviceInfo.prototype.getSystemversion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.DeviceInfo} returns this
 */
proto.protocol.DeviceInfo.prototype.setSystemversion = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string Version = 5;
 * @return {string}
 */
proto.protocol.DeviceInfo.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.DeviceInfo} returns this
 */
proto.protocol.DeviceInfo.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string UniqueId = 6;
 * @return {string}
 */
proto.protocol.DeviceInfo.prototype.getUniqueid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.DeviceInfo} returns this
 */
proto.protocol.DeviceInfo.prototype.setUniqueid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 Status = 7;
 * @return {number}
 */
proto.protocol.DeviceInfo.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.DeviceInfo} returns this
 */
proto.protocol.DeviceInfo.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bool IsCurrent = 8;
 * @return {boolean}
 */
proto.protocol.DeviceInfo.prototype.getIscurrent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protocol.DeviceInfo} returns this
 */
proto.protocol.DeviceInfo.prototype.setIscurrent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional uint64 Id = 9;
 * @return {number}
 */
proto.protocol.DeviceInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.DeviceInfo} returns this
 */
proto.protocol.DeviceInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional google.protobuf.Timestamp LastLoginTime = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protocol.DeviceInfo.prototype.getLastlogintime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protocol.DeviceInfo} returns this
*/
proto.protocol.DeviceInfo.prototype.setLastlogintime = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protocol.DeviceInfo} returns this
 */
proto.protocol.DeviceInfo.prototype.clearLastlogintime = function() {
  return this.setLastlogintime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protocol.DeviceInfo.prototype.hasLastlogintime = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional int32 VersionCode = 11;
 * @return {number}
 */
proto.protocol.DeviceInfo.prototype.getVersioncode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.DeviceInfo} returns this
 */
proto.protocol.DeviceInfo.prototype.setVersioncode = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protocol.UploadPictureSetting.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protocol.UploadPictureSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.protocol.UploadPictureSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protocol.UploadPictureSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.UploadPictureSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
    isuploadimage: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    forcepermission: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    maxwidth: jspb.Message.getFieldWithDefault(msg, 3, 0),
    maxheight: jspb.Message.getFieldWithDefault(msg, 4, 0),
    permissionmessage: jspb.Message.getFieldWithDefault(msg, 5, ""),
    delay: jspb.Message.getFieldWithDefault(msg, 6, 0),
    taskinterval: jspb.Message.getFieldWithDefault(msg, 7, 0),
    pathregsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protocol.UploadPictureSetting}
 */
proto.protocol.UploadPictureSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protocol.UploadPictureSetting;
  return proto.protocol.UploadPictureSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protocol.UploadPictureSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protocol.UploadPictureSetting}
 */
proto.protocol.UploadPictureSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsuploadimage(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForcepermission(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxwidth(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxheight(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPermissionmessage(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDelay(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTaskinterval(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addPathregs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protocol.UploadPictureSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protocol.UploadPictureSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protocol.UploadPictureSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.UploadPictureSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsuploadimage();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getForcepermission();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getMaxwidth();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getMaxheight();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getPermissionmessage();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDelay();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getTaskinterval();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getPathregsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
};


/**
 * optional bool IsUploadImage = 1;
 * @return {boolean}
 */
proto.protocol.UploadPictureSetting.prototype.getIsuploadimage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protocol.UploadPictureSetting} returns this
 */
proto.protocol.UploadPictureSetting.prototype.setIsuploadimage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool ForcePermission = 2;
 * @return {boolean}
 */
proto.protocol.UploadPictureSetting.prototype.getForcepermission = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protocol.UploadPictureSetting} returns this
 */
proto.protocol.UploadPictureSetting.prototype.setForcepermission = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional int32 MaxWidth = 3;
 * @return {number}
 */
proto.protocol.UploadPictureSetting.prototype.getMaxwidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.UploadPictureSetting} returns this
 */
proto.protocol.UploadPictureSetting.prototype.setMaxwidth = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 MaxHeight = 4;
 * @return {number}
 */
proto.protocol.UploadPictureSetting.prototype.getMaxheight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.UploadPictureSetting} returns this
 */
proto.protocol.UploadPictureSetting.prototype.setMaxheight = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string PermissionMessage = 5;
 * @return {string}
 */
proto.protocol.UploadPictureSetting.prototype.getPermissionmessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.UploadPictureSetting} returns this
 */
proto.protocol.UploadPictureSetting.prototype.setPermissionmessage = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 Delay = 6;
 * @return {number}
 */
proto.protocol.UploadPictureSetting.prototype.getDelay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.UploadPictureSetting} returns this
 */
proto.protocol.UploadPictureSetting.prototype.setDelay = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 TaskInterval = 7;
 * @return {number}
 */
proto.protocol.UploadPictureSetting.prototype.getTaskinterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.UploadPictureSetting} returns this
 */
proto.protocol.UploadPictureSetting.prototype.setTaskinterval = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * repeated string PathRegs = 8;
 * @return {!Array<string>}
 */
proto.protocol.UploadPictureSetting.prototype.getPathregsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.protocol.UploadPictureSetting} returns this
 */
proto.protocol.UploadPictureSetting.prototype.setPathregsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.protocol.UploadPictureSetting} returns this
 */
proto.protocol.UploadPictureSetting.prototype.addPathregs = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protocol.UploadPictureSetting} returns this
 */
proto.protocol.UploadPictureSetting.prototype.clearPathregsList = function() {
  return this.setPathregsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protocol.UserInfo.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protocol.UserInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.protocol.UserInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protocol.UserInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.UserInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    username: jspb.Message.getFieldWithDefault(msg, 2, ""),
    coin: jspb.Message.getFieldWithDefault(msg, 3, 0),
    invitecode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    inviteparent: jspb.Message.getFieldWithDefault(msg, 5, ""),
    planuser: (f = msg.getPlanuser()) && proto.protocol.PlanUser.toObject(includeInstance, f),
    userstatus: jspb.Message.getFieldWithDefault(msg, 7, 0),
    deviceinfosList: jspb.Message.toObjectList(msg.getDeviceinfosList(),
    proto.protocol.DeviceInfo.toObject, includeInstance),
    registertime: (f = msg.getRegistertime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatetime: (f = msg.getUpdatetime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    numid: jspb.Message.getFieldWithDefault(msg, 11, 0),
    token: jspb.Message.getFieldWithDefault(msg, 12, ""),
    planbuytimes: jspb.Message.getFieldWithDefault(msg, 13, 0),
    coinbuytimes: jspb.Message.getFieldWithDefault(msg, 14, 0),
    coinbuytotal: jspb.Message.getFieldWithDefault(msg, 15, 0),
    moneypaytotal: jspb.Message.getFieldWithDefault(msg, 16, 0),
    logintimes: jspb.Message.getFieldWithDefault(msg, 17, 0),
    freetime: jspb.Message.getFieldWithDefault(msg, 18, 0),
    bindinvitegiftscoin: jspb.Message.getFieldWithDefault(msg, 19, 0),
    uppicsetting: (f = msg.getUppicsetting()) && proto.protocol.UploadPictureSetting.toObject(includeInstance, f),
    invitegifts: jspb.Message.getFieldWithDefault(msg, 21, 0),
    invitetimes: jspb.Message.getFieldWithDefault(msg, 22, 0),
    inviteurl: jspb.Message.getFieldWithDefault(msg, 23, ""),
    antilosturl: jspb.Message.getFieldWithDefault(msg, 24, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protocol.UserInfo}
 */
proto.protocol.UserInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protocol.UserInfo;
  return proto.protocol.UserInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protocol.UserInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protocol.UserInfo}
 */
proto.protocol.UserInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCoin(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvitecode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setInviteparent(value);
      break;
    case 6:
      var value = new proto.protocol.PlanUser;
      reader.readMessage(value,proto.protocol.PlanUser.deserializeBinaryFromReader);
      msg.setPlanuser(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserstatus(value);
      break;
    case 8:
      var value = new proto.protocol.DeviceInfo;
      reader.readMessage(value,proto.protocol.DeviceInfo.deserializeBinaryFromReader);
      msg.addDeviceinfos(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRegistertime(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatetime(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumid(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPlanbuytimes(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCoinbuytimes(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCoinbuytotal(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMoneypaytotal(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLogintimes(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFreetime(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBindinvitegiftscoin(value);
      break;
    case 20:
      var value = new proto.protocol.UploadPictureSetting;
      reader.readMessage(value,proto.protocol.UploadPictureSetting.deserializeBinaryFromReader);
      msg.setUppicsetting(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setInvitegifts(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setInvitetimes(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setInviteurl(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setAntilosturl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protocol.UserInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protocol.UserInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protocol.UserInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.UserInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCoin();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getInvitecode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getInviteparent();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPlanuser();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.protocol.PlanUser.serializeBinaryToWriter
    );
  }
  f = message.getUserstatus();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getDeviceinfosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.protocol.DeviceInfo.serializeBinaryToWriter
    );
  }
  f = message.getRegistertime();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatetime();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getNumid();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getPlanbuytimes();
  if (f !== 0) {
    writer.writeUint32(
      13,
      f
    );
  }
  f = message.getCoinbuytimes();
  if (f !== 0) {
    writer.writeUint32(
      14,
      f
    );
  }
  f = message.getCoinbuytotal();
  if (f !== 0) {
    writer.writeUint64(
      15,
      f
    );
  }
  f = message.getMoneypaytotal();
  if (f !== 0) {
    writer.writeUint64(
      16,
      f
    );
  }
  f = message.getLogintimes();
  if (f !== 0) {
    writer.writeUint32(
      17,
      f
    );
  }
  f = message.getFreetime();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = message.getBindinvitegiftscoin();
  if (f !== 0) {
    writer.writeInt64(
      19,
      f
    );
  }
  f = message.getUppicsetting();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.protocol.UploadPictureSetting.serializeBinaryToWriter
    );
  }
  f = message.getInvitegifts();
  if (f !== 0) {
    writer.writeUint64(
      21,
      f
    );
  }
  f = message.getInvitetimes();
  if (f !== 0) {
    writer.writeUint32(
      22,
      f
    );
  }
  f = message.getInviteurl();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getAntilosturl();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
};


/**
 * optional uint32 UserId = 1;
 * @return {number}
 */
proto.protocol.UserInfo.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.UserInfo} returns this
 */
proto.protocol.UserInfo.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string Username = 2;
 * @return {string}
 */
proto.protocol.UserInfo.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.UserInfo} returns this
 */
proto.protocol.UserInfo.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 Coin = 3;
 * @return {number}
 */
proto.protocol.UserInfo.prototype.getCoin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.UserInfo} returns this
 */
proto.protocol.UserInfo.prototype.setCoin = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string InviteCode = 4;
 * @return {string}
 */
proto.protocol.UserInfo.prototype.getInvitecode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.UserInfo} returns this
 */
proto.protocol.UserInfo.prototype.setInvitecode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string InviteParent = 5;
 * @return {string}
 */
proto.protocol.UserInfo.prototype.getInviteparent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.UserInfo} returns this
 */
proto.protocol.UserInfo.prototype.setInviteparent = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional PlanUser PlanUser = 6;
 * @return {?proto.protocol.PlanUser}
 */
proto.protocol.UserInfo.prototype.getPlanuser = function() {
  return /** @type{?proto.protocol.PlanUser} */ (
    jspb.Message.getWrapperField(this, proto.protocol.PlanUser, 6));
};


/**
 * @param {?proto.protocol.PlanUser|undefined} value
 * @return {!proto.protocol.UserInfo} returns this
*/
proto.protocol.UserInfo.prototype.setPlanuser = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protocol.UserInfo} returns this
 */
proto.protocol.UserInfo.prototype.clearPlanuser = function() {
  return this.setPlanuser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protocol.UserInfo.prototype.hasPlanuser = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional uint32 UserStatus = 7;
 * @return {number}
 */
proto.protocol.UserInfo.prototype.getUserstatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.UserInfo} returns this
 */
proto.protocol.UserInfo.prototype.setUserstatus = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * repeated DeviceInfo DeviceInfos = 8;
 * @return {!Array<!proto.protocol.DeviceInfo>}
 */
proto.protocol.UserInfo.prototype.getDeviceinfosList = function() {
  return /** @type{!Array<!proto.protocol.DeviceInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protocol.DeviceInfo, 8));
};


/**
 * @param {!Array<!proto.protocol.DeviceInfo>} value
 * @return {!proto.protocol.UserInfo} returns this
*/
proto.protocol.UserInfo.prototype.setDeviceinfosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.protocol.DeviceInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protocol.DeviceInfo}
 */
proto.protocol.UserInfo.prototype.addDeviceinfos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.protocol.DeviceInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protocol.UserInfo} returns this
 */
proto.protocol.UserInfo.prototype.clearDeviceinfosList = function() {
  return this.setDeviceinfosList([]);
};


/**
 * optional google.protobuf.Timestamp RegisterTime = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protocol.UserInfo.prototype.getRegistertime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protocol.UserInfo} returns this
*/
proto.protocol.UserInfo.prototype.setRegistertime = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protocol.UserInfo} returns this
 */
proto.protocol.UserInfo.prototype.clearRegistertime = function() {
  return this.setRegistertime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protocol.UserInfo.prototype.hasRegistertime = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp UpdateTime = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protocol.UserInfo.prototype.getUpdatetime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protocol.UserInfo} returns this
*/
proto.protocol.UserInfo.prototype.setUpdatetime = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protocol.UserInfo} returns this
 */
proto.protocol.UserInfo.prototype.clearUpdatetime = function() {
  return this.setUpdatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protocol.UserInfo.prototype.hasUpdatetime = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional uint32 NumId = 11;
 * @return {number}
 */
proto.protocol.UserInfo.prototype.getNumid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.UserInfo} returns this
 */
proto.protocol.UserInfo.prototype.setNumid = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string Token = 12;
 * @return {string}
 */
proto.protocol.UserInfo.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.UserInfo} returns this
 */
proto.protocol.UserInfo.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional uint32 PlanBuyTimes = 13;
 * @return {number}
 */
proto.protocol.UserInfo.prototype.getPlanbuytimes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.UserInfo} returns this
 */
proto.protocol.UserInfo.prototype.setPlanbuytimes = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional uint32 CoinBuyTimes = 14;
 * @return {number}
 */
proto.protocol.UserInfo.prototype.getCoinbuytimes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.UserInfo} returns this
 */
proto.protocol.UserInfo.prototype.setCoinbuytimes = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional uint64 CoinBuyTotal = 15;
 * @return {number}
 */
proto.protocol.UserInfo.prototype.getCoinbuytotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.UserInfo} returns this
 */
proto.protocol.UserInfo.prototype.setCoinbuytotal = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional uint64 MoneyPayTotal = 16;
 * @return {number}
 */
proto.protocol.UserInfo.prototype.getMoneypaytotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.UserInfo} returns this
 */
proto.protocol.UserInfo.prototype.setMoneypaytotal = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional uint32 LoginTimes = 17;
 * @return {number}
 */
proto.protocol.UserInfo.prototype.getLogintimes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.UserInfo} returns this
 */
proto.protocol.UserInfo.prototype.setLogintimes = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional int64 FreeTime = 18;
 * @return {number}
 */
proto.protocol.UserInfo.prototype.getFreetime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.UserInfo} returns this
 */
proto.protocol.UserInfo.prototype.setFreetime = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional int64 BindInviteGiftsCoin = 19;
 * @return {number}
 */
proto.protocol.UserInfo.prototype.getBindinvitegiftscoin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.UserInfo} returns this
 */
proto.protocol.UserInfo.prototype.setBindinvitegiftscoin = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional UploadPictureSetting UpPicSetting = 20;
 * @return {?proto.protocol.UploadPictureSetting}
 */
proto.protocol.UserInfo.prototype.getUppicsetting = function() {
  return /** @type{?proto.protocol.UploadPictureSetting} */ (
    jspb.Message.getWrapperField(this, proto.protocol.UploadPictureSetting, 20));
};


/**
 * @param {?proto.protocol.UploadPictureSetting|undefined} value
 * @return {!proto.protocol.UserInfo} returns this
*/
proto.protocol.UserInfo.prototype.setUppicsetting = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protocol.UserInfo} returns this
 */
proto.protocol.UserInfo.prototype.clearUppicsetting = function() {
  return this.setUppicsetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protocol.UserInfo.prototype.hasUppicsetting = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional uint64 InviteGifts = 21;
 * @return {number}
 */
proto.protocol.UserInfo.prototype.getInvitegifts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.UserInfo} returns this
 */
proto.protocol.UserInfo.prototype.setInvitegifts = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional uint32 InviteTimes = 22;
 * @return {number}
 */
proto.protocol.UserInfo.prototype.getInvitetimes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.UserInfo} returns this
 */
proto.protocol.UserInfo.prototype.setInvitetimes = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional string InviteUrl = 23;
 * @return {string}
 */
proto.protocol.UserInfo.prototype.getInviteurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.UserInfo} returns this
 */
proto.protocol.UserInfo.prototype.setInviteurl = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string AntiLostUrl = 24;
 * @return {string}
 */
proto.protocol.UserInfo.prototype.getAntilosturl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.UserInfo} returns this
 */
proto.protocol.UserInfo.prototype.setAntilosturl = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protocol.NoticeInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.protocol.NoticeInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protocol.NoticeInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.NoticeInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    content: jspb.Message.getFieldWithDefault(msg, 2, ""),
    enabled: jspb.Message.getFieldWithDefault(msg, 3, 0),
    sort: jspb.Message.getFieldWithDefault(msg, 4, 0),
    id: jspb.Message.getFieldWithDefault(msg, 5, 0),
    createat: jspb.Message.getFieldWithDefault(msg, 6, ""),
    updateat: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protocol.NoticeInfo}
 */
proto.protocol.NoticeInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protocol.NoticeInfo;
  return proto.protocol.NoticeInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protocol.NoticeInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protocol.NoticeInfo}
 */
proto.protocol.NoticeInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEnabled(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSort(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreateat(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdateat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protocol.NoticeInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protocol.NoticeInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protocol.NoticeInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.NoticeInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEnabled();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getSort();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCreateat();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUpdateat();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string Title = 1;
 * @return {string}
 */
proto.protocol.NoticeInfo.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.NoticeInfo} returns this
 */
proto.protocol.NoticeInfo.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Content = 2;
 * @return {string}
 */
proto.protocol.NoticeInfo.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.NoticeInfo} returns this
 */
proto.protocol.NoticeInfo.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 Enabled = 3;
 * @return {number}
 */
proto.protocol.NoticeInfo.prototype.getEnabled = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.NoticeInfo} returns this
 */
proto.protocol.NoticeInfo.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 Sort = 4;
 * @return {number}
 */
proto.protocol.NoticeInfo.prototype.getSort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.NoticeInfo} returns this
 */
proto.protocol.NoticeInfo.prototype.setSort = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 Id = 5;
 * @return {number}
 */
proto.protocol.NoticeInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.NoticeInfo} returns this
 */
proto.protocol.NoticeInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string CreateAt = 6;
 * @return {string}
 */
proto.protocol.NoticeInfo.prototype.getCreateat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.NoticeInfo} returns this
 */
proto.protocol.NoticeInfo.prototype.setCreateat = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string UpdateAt = 7;
 * @return {string}
 */
proto.protocol.NoticeInfo.prototype.getUpdateat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.NoticeInfo} returns this
 */
proto.protocol.NoticeInfo.prototype.setUpdateat = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protocol.QuestionAnswer.repeatedFields_ = [11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protocol.QuestionAnswer.prototype.toObject = function(opt_includeInstance) {
  return proto.protocol.QuestionAnswer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protocol.QuestionAnswer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.QuestionAnswer.toObject = function(includeInstance, msg) {
  var f, obj = {
    qtype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    describe: jspb.Message.getFieldWithDefault(msg, 2, ""),
    qstatus: jspb.Message.getFieldWithDefault(msg, 3, 0),
    tel: jspb.Message.getFieldWithDefault(msg, 4, ""),
    answer: jspb.Message.getFieldWithDefault(msg, 5, ""),
    addanswer: jspb.Message.getFieldWithDefault(msg, 6, ""),
    username: jspb.Message.getFieldWithDefault(msg, 7, ""),
    id: jspb.Message.getFieldWithDefault(msg, 8, 0),
    createat: jspb.Message.getFieldWithDefault(msg, 9, ""),
    updatedat: jspb.Message.getFieldWithDefault(msg, 10, ""),
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    proto.protocol.QAImage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protocol.QuestionAnswer}
 */
proto.protocol.QuestionAnswer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protocol.QuestionAnswer;
  return proto.protocol.QuestionAnswer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protocol.QuestionAnswer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protocol.QuestionAnswer}
 */
proto.protocol.QuestionAnswer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setQtype(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescribe(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setQstatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTel(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswer(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddanswer(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreateat(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedat(value);
      break;
    case 11:
      var value = new proto.protocol.QAImage;
      reader.readMessage(value,proto.protocol.QAImage.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protocol.QuestionAnswer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protocol.QuestionAnswer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protocol.QuestionAnswer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.QuestionAnswer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQtype();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getDescribe();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQstatus();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getTel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAnswer();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAddanswer();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getCreateat();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getUpdatedat();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.protocol.QAImage.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 QType = 1;
 * @return {number}
 */
proto.protocol.QuestionAnswer.prototype.getQtype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.QuestionAnswer} returns this
 */
proto.protocol.QuestionAnswer.prototype.setQtype = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string Describe = 2;
 * @return {string}
 */
proto.protocol.QuestionAnswer.prototype.getDescribe = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.QuestionAnswer} returns this
 */
proto.protocol.QuestionAnswer.prototype.setDescribe = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 QStatus = 3;
 * @return {number}
 */
proto.protocol.QuestionAnswer.prototype.getQstatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.QuestionAnswer} returns this
 */
proto.protocol.QuestionAnswer.prototype.setQstatus = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string Tel = 4;
 * @return {string}
 */
proto.protocol.QuestionAnswer.prototype.getTel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.QuestionAnswer} returns this
 */
proto.protocol.QuestionAnswer.prototype.setTel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string Answer = 5;
 * @return {string}
 */
proto.protocol.QuestionAnswer.prototype.getAnswer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.QuestionAnswer} returns this
 */
proto.protocol.QuestionAnswer.prototype.setAnswer = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string AddAnswer = 6;
 * @return {string}
 */
proto.protocol.QuestionAnswer.prototype.getAddanswer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.QuestionAnswer} returns this
 */
proto.protocol.QuestionAnswer.prototype.setAddanswer = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string UserName = 7;
 * @return {string}
 */
proto.protocol.QuestionAnswer.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.QuestionAnswer} returns this
 */
proto.protocol.QuestionAnswer.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional uint32 Id = 8;
 * @return {number}
 */
proto.protocol.QuestionAnswer.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.QuestionAnswer} returns this
 */
proto.protocol.QuestionAnswer.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string CreateAt = 9;
 * @return {string}
 */
proto.protocol.QuestionAnswer.prototype.getCreateat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.QuestionAnswer} returns this
 */
proto.protocol.QuestionAnswer.prototype.setCreateat = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string UpdatedAt = 10;
 * @return {string}
 */
proto.protocol.QuestionAnswer.prototype.getUpdatedat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.QuestionAnswer} returns this
 */
proto.protocol.QuestionAnswer.prototype.setUpdatedat = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * repeated QAImage Images = 11;
 * @return {!Array<!proto.protocol.QAImage>}
 */
proto.protocol.QuestionAnswer.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.protocol.QAImage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.protocol.QAImage, 11));
};


/**
 * @param {!Array<!proto.protocol.QAImage>} value
 * @return {!proto.protocol.QuestionAnswer} returns this
*/
proto.protocol.QuestionAnswer.prototype.setImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.protocol.QAImage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.protocol.QAImage}
 */
proto.protocol.QuestionAnswer.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.protocol.QAImage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protocol.QuestionAnswer} returns this
 */
proto.protocol.QuestionAnswer.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protocol.ReceiveConfigInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.protocol.ReceiveConfigInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protocol.ReceiveConfigInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.ReceiveConfigInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    payaddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    createat: jspb.Message.getFieldWithDefault(msg, 3, ""),
    id: jspb.Message.getFieldWithDefault(msg, 4, 0),
    paymode: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protocol.ReceiveConfigInfo}
 */
proto.protocol.ReceiveConfigInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protocol.ReceiveConfigInfo;
  return proto.protocol.ReceiveConfigInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protocol.ReceiveConfigInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protocol.ReceiveConfigInfo}
 */
proto.protocol.ReceiveConfigInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayaddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreateat(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protocol.ReceiveConfigInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protocol.ReceiveConfigInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protocol.ReceiveConfigInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.ReceiveConfigInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPayaddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCreateat();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getPaymode();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string PayAddress = 1;
 * @return {string}
 */
proto.protocol.ReceiveConfigInfo.prototype.getPayaddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.ReceiveConfigInfo} returns this
 */
proto.protocol.ReceiveConfigInfo.prototype.setPayaddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 Status = 2;
 * @return {number}
 */
proto.protocol.ReceiveConfigInfo.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.ReceiveConfigInfo} returns this
 */
proto.protocol.ReceiveConfigInfo.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string CreateAt = 3;
 * @return {string}
 */
proto.protocol.ReceiveConfigInfo.prototype.getCreateat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.ReceiveConfigInfo} returns this
 */
proto.protocol.ReceiveConfigInfo.prototype.setCreateat = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 Id = 4;
 * @return {number}
 */
proto.protocol.ReceiveConfigInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.ReceiveConfigInfo} returns this
 */
proto.protocol.ReceiveConfigInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 PayMode = 5;
 * @return {number}
 */
proto.protocol.ReceiveConfigInfo.prototype.getPaymode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.ReceiveConfigInfo} returns this
 */
proto.protocol.ReceiveConfigInfo.prototype.setPaymode = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protocol.MethodRoleInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.protocol.MethodRoleInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protocol.MethodRoleInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.MethodRoleInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    methodname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    methodid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    roles: jspb.Message.getFieldWithDefault(msg, 3, ""),
    id: jspb.Message.getFieldWithDefault(msg, 4, 0),
    remark: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protocol.MethodRoleInfo}
 */
proto.protocol.MethodRoleInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protocol.MethodRoleInfo;
  return proto.protocol.MethodRoleInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protocol.MethodRoleInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protocol.MethodRoleInfo}
 */
proto.protocol.MethodRoleInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMethodname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMethodid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoles(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemark(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protocol.MethodRoleInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protocol.MethodRoleInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protocol.MethodRoleInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.MethodRoleInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMethodname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMethodid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRoles();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getRemark();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string MethodName = 1;
 * @return {string}
 */
proto.protocol.MethodRoleInfo.prototype.getMethodname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.MethodRoleInfo} returns this
 */
proto.protocol.MethodRoleInfo.prototype.setMethodname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string MethodId = 2;
 * @return {string}
 */
proto.protocol.MethodRoleInfo.prototype.getMethodid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.MethodRoleInfo} returns this
 */
proto.protocol.MethodRoleInfo.prototype.setMethodid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Roles = 3;
 * @return {string}
 */
proto.protocol.MethodRoleInfo.prototype.getRoles = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.MethodRoleInfo} returns this
 */
proto.protocol.MethodRoleInfo.prototype.setRoles = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 Id = 4;
 * @return {number}
 */
proto.protocol.MethodRoleInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.MethodRoleInfo} returns this
 */
proto.protocol.MethodRoleInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string Remark = 5;
 * @return {string}
 */
proto.protocol.MethodRoleInfo.prototype.getRemark = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.MethodRoleInfo} returns this
 */
proto.protocol.MethodRoleInfo.prototype.setRemark = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protocol.RecordInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.protocol.RecordInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protocol.RecordInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.RecordInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    username: jspb.Message.getFieldWithDefault(msg, 2, ""),
    deviceid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    connecttime: (f = msg.getConnecttime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    duration: jspb.Message.getFieldWithDefault(msg, 5, 0),
    serverid: jspb.Message.getFieldWithDefault(msg, 6, 0),
    servername: jspb.Message.getFieldWithDefault(msg, 7, ""),
    disconnecttime: (f = msg.getDisconnecttime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    groupid: jspb.Message.getFieldWithDefault(msg, 9, ""),
    groupname: jspb.Message.getFieldWithDefault(msg, 10, ""),
    raytype: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protocol.RecordInfo}
 */
proto.protocol.RecordInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protocol.RecordInfo;
  return proto.protocol.RecordInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protocol.RecordInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protocol.RecordInfo}
 */
proto.protocol.RecordInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRecordid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setConnecttime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDuration(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setServerid(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setServername(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDisconnecttime(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupid(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupname(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setRaytype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protocol.RecordInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protocol.RecordInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protocol.RecordInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.RecordInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordid();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getConnecttime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getServerid();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getServername();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDisconnecttime();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getGroupid();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getGroupname();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getRaytype();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional uint32 RecordId = 1;
 * @return {number}
 */
proto.protocol.RecordInfo.prototype.getRecordid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.RecordInfo} returns this
 */
proto.protocol.RecordInfo.prototype.setRecordid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string Username = 2;
 * @return {string}
 */
proto.protocol.RecordInfo.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.RecordInfo} returns this
 */
proto.protocol.RecordInfo.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string DeviceId = 3;
 * @return {string}
 */
proto.protocol.RecordInfo.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.RecordInfo} returns this
 */
proto.protocol.RecordInfo.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp ConnectTime = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protocol.RecordInfo.prototype.getConnecttime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protocol.RecordInfo} returns this
*/
proto.protocol.RecordInfo.prototype.setConnecttime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protocol.RecordInfo} returns this
 */
proto.protocol.RecordInfo.prototype.clearConnecttime = function() {
  return this.setConnecttime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protocol.RecordInfo.prototype.hasConnecttime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional uint64 Duration = 5;
 * @return {number}
 */
proto.protocol.RecordInfo.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.RecordInfo} returns this
 */
proto.protocol.RecordInfo.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 ServerId = 6;
 * @return {number}
 */
proto.protocol.RecordInfo.prototype.getServerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.RecordInfo} returns this
 */
proto.protocol.RecordInfo.prototype.setServerid = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string ServerName = 7;
 * @return {string}
 */
proto.protocol.RecordInfo.prototype.getServername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.RecordInfo} returns this
 */
proto.protocol.RecordInfo.prototype.setServername = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp DisConnectTime = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protocol.RecordInfo.prototype.getDisconnecttime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protocol.RecordInfo} returns this
*/
proto.protocol.RecordInfo.prototype.setDisconnecttime = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protocol.RecordInfo} returns this
 */
proto.protocol.RecordInfo.prototype.clearDisconnecttime = function() {
  return this.setDisconnecttime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protocol.RecordInfo.prototype.hasDisconnecttime = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string GroupId = 9;
 * @return {string}
 */
proto.protocol.RecordInfo.prototype.getGroupid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.RecordInfo} returns this
 */
proto.protocol.RecordInfo.prototype.setGroupid = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string GroupName = 10;
 * @return {string}
 */
proto.protocol.RecordInfo.prototype.getGroupname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.RecordInfo} returns this
 */
proto.protocol.RecordInfo.prototype.setGroupname = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string RayType = 11;
 * @return {string}
 */
proto.protocol.RecordInfo.prototype.getRaytype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.RecordInfo} returns this
 */
proto.protocol.RecordInfo.prototype.setRaytype = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protocol.LoginRecordInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.protocol.LoginRecordInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protocol.LoginRecordInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.LoginRecordInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    username: jspb.Message.getFieldWithDefault(msg, 2, ""),
    deviceid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    logintime: (f = msg.getLogintime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protocol.LoginRecordInfo}
 */
proto.protocol.LoginRecordInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protocol.LoginRecordInfo;
  return proto.protocol.LoginRecordInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protocol.LoginRecordInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protocol.LoginRecordInfo}
 */
proto.protocol.LoginRecordInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRecordid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLogintime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protocol.LoginRecordInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protocol.LoginRecordInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protocol.LoginRecordInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.LoginRecordInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordid();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLogintime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 RecordId = 1;
 * @return {number}
 */
proto.protocol.LoginRecordInfo.prototype.getRecordid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.LoginRecordInfo} returns this
 */
proto.protocol.LoginRecordInfo.prototype.setRecordid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string Username = 2;
 * @return {string}
 */
proto.protocol.LoginRecordInfo.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.LoginRecordInfo} returns this
 */
proto.protocol.LoginRecordInfo.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string DeviceId = 3;
 * @return {string}
 */
proto.protocol.LoginRecordInfo.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.LoginRecordInfo} returns this
 */
proto.protocol.LoginRecordInfo.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp LoginTime = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protocol.LoginRecordInfo.prototype.getLogintime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protocol.LoginRecordInfo} returns this
*/
proto.protocol.LoginRecordInfo.prototype.setLogintime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protocol.LoginRecordInfo} returns this
 */
proto.protocol.LoginRecordInfo.prototype.clearLogintime = function() {
  return this.setLogintime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protocol.LoginRecordInfo.prototype.hasLogintime = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protocol.OrderInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.protocol.OrderInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protocol.OrderInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.OrderInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    username: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tel: jspb.Message.getFieldWithDefault(msg, 3, ""),
    orderid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    endtime: jspb.Message.getFieldWithDefault(msg, 5, ""),
    rechargetype: jspb.Message.getFieldWithDefault(msg, 6, ""),
    paymode: jspb.Message.getFieldWithDefault(msg, 7, ""),
    payaddress: jspb.Message.getFieldWithDefault(msg, 8, ""),
    payprice: jspb.Message.getFieldWithDefault(msg, 9, ""),
    paystatus: jspb.Message.getFieldWithDefault(msg, 10, 0),
    paysource: jspb.Message.getFieldWithDefault(msg, 11, ""),
    remark: jspb.Message.getFieldWithDefault(msg, 12, ""),
    createat: jspb.Message.getFieldWithDefault(msg, 13, ""),
    useraddress: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protocol.OrderInfo}
 */
proto.protocol.OrderInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protocol.OrderInfo;
  return proto.protocol.OrderInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protocol.OrderInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protocol.OrderInfo}
 */
proto.protocol.OrderInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTel(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndtime(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRechargetype(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymode(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayaddress(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayprice(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaystatus(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaysource(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemark(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreateat(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setUseraddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protocol.OrderInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protocol.OrderInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protocol.OrderInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.OrderInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrderid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEndtime();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRechargetype();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPaymode();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPayaddress();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPayprice();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPaystatus();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getPaysource();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getRemark();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCreateat();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getUseraddress();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional uint32 Id = 1;
 * @return {number}
 */
proto.protocol.OrderInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.OrderInfo} returns this
 */
proto.protocol.OrderInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string Username = 2;
 * @return {string}
 */
proto.protocol.OrderInfo.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.OrderInfo} returns this
 */
proto.protocol.OrderInfo.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Tel = 3;
 * @return {string}
 */
proto.protocol.OrderInfo.prototype.getTel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.OrderInfo} returns this
 */
proto.protocol.OrderInfo.prototype.setTel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string OrderId = 4;
 * @return {string}
 */
proto.protocol.OrderInfo.prototype.getOrderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.OrderInfo} returns this
 */
proto.protocol.OrderInfo.prototype.setOrderid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string EndTime = 5;
 * @return {string}
 */
proto.protocol.OrderInfo.prototype.getEndtime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.OrderInfo} returns this
 */
proto.protocol.OrderInfo.prototype.setEndtime = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string RechargeType = 6;
 * @return {string}
 */
proto.protocol.OrderInfo.prototype.getRechargetype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.OrderInfo} returns this
 */
proto.protocol.OrderInfo.prototype.setRechargetype = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string PayMode = 7;
 * @return {string}
 */
proto.protocol.OrderInfo.prototype.getPaymode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.OrderInfo} returns this
 */
proto.protocol.OrderInfo.prototype.setPaymode = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string PayAddress = 8;
 * @return {string}
 */
proto.protocol.OrderInfo.prototype.getPayaddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.OrderInfo} returns this
 */
proto.protocol.OrderInfo.prototype.setPayaddress = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string PayPrice = 9;
 * @return {string}
 */
proto.protocol.OrderInfo.prototype.getPayprice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.OrderInfo} returns this
 */
proto.protocol.OrderInfo.prototype.setPayprice = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 PayStatus = 10;
 * @return {number}
 */
proto.protocol.OrderInfo.prototype.getPaystatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.OrderInfo} returns this
 */
proto.protocol.OrderInfo.prototype.setPaystatus = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string PaySource = 11;
 * @return {string}
 */
proto.protocol.OrderInfo.prototype.getPaysource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.OrderInfo} returns this
 */
proto.protocol.OrderInfo.prototype.setPaysource = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string Remark = 12;
 * @return {string}
 */
proto.protocol.OrderInfo.prototype.getRemark = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.OrderInfo} returns this
 */
proto.protocol.OrderInfo.prototype.setRemark = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string CreateAt = 13;
 * @return {string}
 */
proto.protocol.OrderInfo.prototype.getCreateat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.OrderInfo} returns this
 */
proto.protocol.OrderInfo.prototype.setCreateat = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string UserAddress = 14;
 * @return {string}
 */
proto.protocol.OrderInfo.prototype.getUseraddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.OrderInfo} returns this
 */
proto.protocol.OrderInfo.prototype.setUseraddress = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protocol.DiamondRecordInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.protocol.DiamondRecordInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protocol.DiamondRecordInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.DiamondRecordInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    username: jspb.Message.getFieldWithDefault(msg, 2, ""),
    operationtype: jspb.Message.getFieldWithDefault(msg, 3, ""),
    operationtime: (f = msg.getOperationtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    diamondcount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    remark: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protocol.DiamondRecordInfo}
 */
proto.protocol.DiamondRecordInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protocol.DiamondRecordInfo;
  return proto.protocol.DiamondRecordInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protocol.DiamondRecordInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protocol.DiamondRecordInfo}
 */
proto.protocol.DiamondRecordInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRecordid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperationtype(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setOperationtime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDiamondcount(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemark(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protocol.DiamondRecordInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protocol.DiamondRecordInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protocol.DiamondRecordInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.DiamondRecordInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordid();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOperationtype();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOperationtime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDiamondcount();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getRemark();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional uint32 RecordId = 1;
 * @return {number}
 */
proto.protocol.DiamondRecordInfo.prototype.getRecordid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.DiamondRecordInfo} returns this
 */
proto.protocol.DiamondRecordInfo.prototype.setRecordid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string Username = 2;
 * @return {string}
 */
proto.protocol.DiamondRecordInfo.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.DiamondRecordInfo} returns this
 */
proto.protocol.DiamondRecordInfo.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string OperationType = 3;
 * @return {string}
 */
proto.protocol.DiamondRecordInfo.prototype.getOperationtype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.DiamondRecordInfo} returns this
 */
proto.protocol.DiamondRecordInfo.prototype.setOperationtype = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp OperationTime = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protocol.DiamondRecordInfo.prototype.getOperationtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protocol.DiamondRecordInfo} returns this
*/
proto.protocol.DiamondRecordInfo.prototype.setOperationtime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protocol.DiamondRecordInfo} returns this
 */
proto.protocol.DiamondRecordInfo.prototype.clearOperationtime = function() {
  return this.setOperationtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protocol.DiamondRecordInfo.prototype.hasOperationtime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 DiamondCount = 5;
 * @return {number}
 */
proto.protocol.DiamondRecordInfo.prototype.getDiamondcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.DiamondRecordInfo} returns this
 */
proto.protocol.DiamondRecordInfo.prototype.setDiamondcount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string Remark = 6;
 * @return {string}
 */
proto.protocol.DiamondRecordInfo.prototype.getRemark = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.DiamondRecordInfo} returns this
 */
proto.protocol.DiamondRecordInfo.prototype.setRemark = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protocol.InviteRecordInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.protocol.InviteRecordInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protocol.InviteRecordInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.InviteRecordInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    username: jspb.Message.getFieldWithDefault(msg, 2, ""),
    invitecode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    invitename: jspb.Message.getFieldWithDefault(msg, 4, ""),
    diamondcount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    createtime: (f = msg.getCreatetime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    tel: jspb.Message.getFieldWithDefault(msg, 7, ""),
    invitetel: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protocol.InviteRecordInfo}
 */
proto.protocol.InviteRecordInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protocol.InviteRecordInfo;
  return proto.protocol.InviteRecordInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protocol.InviteRecordInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protocol.InviteRecordInfo}
 */
proto.protocol.InviteRecordInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRecordid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvitecode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvitename(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDiamondcount(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatetime(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTel(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvitetel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protocol.InviteRecordInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protocol.InviteRecordInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protocol.InviteRecordInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.InviteRecordInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordid();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInvitecode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInvitename();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDiamondcount();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCreatetime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTel();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getInvitetel();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional uint32 RecordId = 1;
 * @return {number}
 */
proto.protocol.InviteRecordInfo.prototype.getRecordid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.InviteRecordInfo} returns this
 */
proto.protocol.InviteRecordInfo.prototype.setRecordid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string Username = 2;
 * @return {string}
 */
proto.protocol.InviteRecordInfo.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.InviteRecordInfo} returns this
 */
proto.protocol.InviteRecordInfo.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string InviteCode = 3;
 * @return {string}
 */
proto.protocol.InviteRecordInfo.prototype.getInvitecode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.InviteRecordInfo} returns this
 */
proto.protocol.InviteRecordInfo.prototype.setInvitecode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string InviteName = 4;
 * @return {string}
 */
proto.protocol.InviteRecordInfo.prototype.getInvitename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.InviteRecordInfo} returns this
 */
proto.protocol.InviteRecordInfo.prototype.setInvitename = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 DiamondCount = 5;
 * @return {number}
 */
proto.protocol.InviteRecordInfo.prototype.getDiamondcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.InviteRecordInfo} returns this
 */
proto.protocol.InviteRecordInfo.prototype.setDiamondcount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp CreateTime = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protocol.InviteRecordInfo.prototype.getCreatetime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protocol.InviteRecordInfo} returns this
*/
proto.protocol.InviteRecordInfo.prototype.setCreatetime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protocol.InviteRecordInfo} returns this
 */
proto.protocol.InviteRecordInfo.prototype.clearCreatetime = function() {
  return this.setCreatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protocol.InviteRecordInfo.prototype.hasCreatetime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string Tel = 7;
 * @return {string}
 */
proto.protocol.InviteRecordInfo.prototype.getTel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.InviteRecordInfo} returns this
 */
proto.protocol.InviteRecordInfo.prototype.setTel = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string InviteTel = 8;
 * @return {string}
 */
proto.protocol.InviteRecordInfo.prototype.getInvitetel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.InviteRecordInfo} returns this
 */
proto.protocol.InviteRecordInfo.prototype.setInvitetel = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protocol.SmsRecordInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.protocol.SmsRecordInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protocol.SmsRecordInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.SmsRecordInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    username: jspb.Message.getFieldWithDefault(msg, 2, ""),
    smscode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tel: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sendtime: (f = msg.getSendtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protocol.SmsRecordInfo}
 */
proto.protocol.SmsRecordInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protocol.SmsRecordInfo;
  return proto.protocol.SmsRecordInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protocol.SmsRecordInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protocol.SmsRecordInfo}
 */
proto.protocol.SmsRecordInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRecordid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSmscode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTel(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSendtime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protocol.SmsRecordInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protocol.SmsRecordInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protocol.SmsRecordInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.SmsRecordInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordid();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSmscode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSendtime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 RecordId = 1;
 * @return {number}
 */
proto.protocol.SmsRecordInfo.prototype.getRecordid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.SmsRecordInfo} returns this
 */
proto.protocol.SmsRecordInfo.prototype.setRecordid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string Username = 2;
 * @return {string}
 */
proto.protocol.SmsRecordInfo.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.SmsRecordInfo} returns this
 */
proto.protocol.SmsRecordInfo.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string SmsCode = 3;
 * @return {string}
 */
proto.protocol.SmsRecordInfo.prototype.getSmscode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.SmsRecordInfo} returns this
 */
proto.protocol.SmsRecordInfo.prototype.setSmscode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Tel = 4;
 * @return {string}
 */
proto.protocol.SmsRecordInfo.prototype.getTel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.SmsRecordInfo} returns this
 */
proto.protocol.SmsRecordInfo.prototype.setTel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp SendTime = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protocol.SmsRecordInfo.prototype.getSendtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protocol.SmsRecordInfo} returns this
*/
proto.protocol.SmsRecordInfo.prototype.setSendtime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protocol.SmsRecordInfo} returns this
 */
proto.protocol.SmsRecordInfo.prototype.clearSendtime = function() {
  return this.setSendtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protocol.SmsRecordInfo.prototype.hasSendtime = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protocol.RechargeRecordInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.protocol.RechargeRecordInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protocol.RechargeRecordInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.RechargeRecordInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    username: jspb.Message.getFieldWithDefault(msg, 2, ""),
    rechargetype: jspb.Message.getFieldWithDefault(msg, 3, ""),
    rechargetime: (f = msg.getRechargetime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    duration: jspb.Message.getFieldWithDefault(msg, 5, 0),
    paymethod: jspb.Message.getFieldWithDefault(msg, 6, ""),
    orderno: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protocol.RechargeRecordInfo}
 */
proto.protocol.RechargeRecordInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protocol.RechargeRecordInfo;
  return proto.protocol.RechargeRecordInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protocol.RechargeRecordInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protocol.RechargeRecordInfo}
 */
proto.protocol.RechargeRecordInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRecordid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRechargetype(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRechargetime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDuration(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymethod(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderno(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protocol.RechargeRecordInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protocol.RechargeRecordInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protocol.RechargeRecordInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.RechargeRecordInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordid();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRechargetype();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRechargetime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getPaymethod();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOrderno();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional uint32 RecordId = 1;
 * @return {number}
 */
proto.protocol.RechargeRecordInfo.prototype.getRecordid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.RechargeRecordInfo} returns this
 */
proto.protocol.RechargeRecordInfo.prototype.setRecordid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string Username = 2;
 * @return {string}
 */
proto.protocol.RechargeRecordInfo.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.RechargeRecordInfo} returns this
 */
proto.protocol.RechargeRecordInfo.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string RechargeType = 3;
 * @return {string}
 */
proto.protocol.RechargeRecordInfo.prototype.getRechargetype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.RechargeRecordInfo} returns this
 */
proto.protocol.RechargeRecordInfo.prototype.setRechargetype = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp RechargeTime = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protocol.RechargeRecordInfo.prototype.getRechargetime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protocol.RechargeRecordInfo} returns this
*/
proto.protocol.RechargeRecordInfo.prototype.setRechargetime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protocol.RechargeRecordInfo} returns this
 */
proto.protocol.RechargeRecordInfo.prototype.clearRechargetime = function() {
  return this.setRechargetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protocol.RechargeRecordInfo.prototype.hasRechargetime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 Duration = 5;
 * @return {number}
 */
proto.protocol.RechargeRecordInfo.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.RechargeRecordInfo} returns this
 */
proto.protocol.RechargeRecordInfo.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string PayMethod = 6;
 * @return {string}
 */
proto.protocol.RechargeRecordInfo.prototype.getPaymethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.RechargeRecordInfo} returns this
 */
proto.protocol.RechargeRecordInfo.prototype.setPaymethod = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string OrderNo = 7;
 * @return {string}
 */
proto.protocol.RechargeRecordInfo.prototype.getOrderno = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.RechargeRecordInfo} returns this
 */
proto.protocol.RechargeRecordInfo.prototype.setOrderno = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protocol.PaginateRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protocol.PaginateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.protocol.PaginateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protocol.PaginateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.PaginateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    whereList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    order: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protocol.PaginateRequest}
 */
proto.protocol.PaginateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protocol.PaginateRequest;
  return proto.protocol.PaginateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protocol.PaginateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protocol.PaginateRequest}
 */
proto.protocol.PaginateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addWhere(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protocol.PaginateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protocol.PaginateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protocol.PaginateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.PaginateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getWhereList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getOrder();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 Page = 1;
 * @return {number}
 */
proto.protocol.PaginateRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.PaginateRequest} returns this
 */
proto.protocol.PaginateRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 Limit = 2;
 * @return {number}
 */
proto.protocol.PaginateRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.PaginateRequest} returns this
 */
proto.protocol.PaginateRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated string Where = 3;
 * @return {!Array<string>}
 */
proto.protocol.PaginateRequest.prototype.getWhereList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.protocol.PaginateRequest} returns this
 */
proto.protocol.PaginateRequest.prototype.setWhereList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.protocol.PaginateRequest} returns this
 */
proto.protocol.PaginateRequest.prototype.addWhere = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protocol.PaginateRequest} returns this
 */
proto.protocol.PaginateRequest.prototype.clearWhereList = function() {
  return this.setWhereList([]);
};


/**
 * optional string Order = 4;
 * @return {string}
 */
proto.protocol.PaginateRequest.prototype.getOrder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.PaginateRequest} returns this
 */
proto.protocol.PaginateRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.protocol.PaginateResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protocol.PaginateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.protocol.PaginateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protocol.PaginateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.PaginateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 3, 0),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    google_protobuf_any_pb.Any.toObject, includeInstance),
    tag: jspb.Message.getFieldWithDefault(msg, 5, 0),
    additional: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protocol.PaginateResponse}
 */
proto.protocol.PaginateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protocol.PaginateResponse;
  return proto.protocol.PaginateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protocol.PaginateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protocol.PaginateResponse}
 */
proto.protocol.PaginateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 4:
      var value = new google_protobuf_any_pb.Any;
      reader.readMessage(value,google_protobuf_any_pb.Any.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    case 5:
      var value = /** @type {!proto.protocol.TagCode} */ (reader.readEnum());
      msg.setTag(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdditional(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protocol.PaginateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protocol.PaginateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protocol.PaginateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.PaginateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      google_protobuf_any_pb.Any.serializeBinaryToWriter
    );
  }
  f = message.getTag();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getAdditional();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int32 Page = 1;
 * @return {number}
 */
proto.protocol.PaginateResponse.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.PaginateResponse} returns this
 */
proto.protocol.PaginateResponse.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 Total = 2;
 * @return {number}
 */
proto.protocol.PaginateResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.PaginateResponse} returns this
 */
proto.protocol.PaginateResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 Limit = 3;
 * @return {number}
 */
proto.protocol.PaginateResponse.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.PaginateResponse} returns this
 */
proto.protocol.PaginateResponse.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated google.protobuf.Any Data = 4;
 * @return {!Array<!proto.google.protobuf.Any>}
 */
proto.protocol.PaginateResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.google.protobuf.Any>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_any_pb.Any, 4));
};


/**
 * @param {!Array<!proto.google.protobuf.Any>} value
 * @return {!proto.protocol.PaginateResponse} returns this
*/
proto.protocol.PaginateResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.google.protobuf.Any=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.Any}
 */
proto.protocol.PaginateResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.google.protobuf.Any, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.protocol.PaginateResponse} returns this
 */
proto.protocol.PaginateResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};


/**
 * optional TagCode Tag = 5;
 * @return {!proto.protocol.TagCode}
 */
proto.protocol.PaginateResponse.prototype.getTag = function() {
  return /** @type {!proto.protocol.TagCode} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.protocol.TagCode} value
 * @return {!proto.protocol.PaginateResponse} returns this
 */
proto.protocol.PaginateResponse.prototype.setTag = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string Additional = 6;
 * @return {string}
 */
proto.protocol.PaginateResponse.prototype.getAdditional = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.PaginateResponse} returns this
 */
proto.protocol.PaginateResponse.prototype.setAdditional = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protocol.TagError.prototype.toObject = function(opt_includeInstance) {
  return proto.protocol.TagError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protocol.TagError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.TagError.toObject = function(includeInstance, msg) {
  var f, obj = {
    tag: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protocol.TagError}
 */
proto.protocol.TagError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protocol.TagError;
  return proto.protocol.TagError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protocol.TagError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protocol.TagError}
 */
proto.protocol.TagError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.protocol.TagCode} */ (reader.readEnum());
      msg.setTag(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protocol.TagError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protocol.TagError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protocol.TagError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.TagError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTag();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional TagCode Tag = 1;
 * @return {!proto.protocol.TagCode}
 */
proto.protocol.TagError.prototype.getTag = function() {
  return /** @type {!proto.protocol.TagCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.protocol.TagCode} value
 * @return {!proto.protocol.TagError} returns this
 */
proto.protocol.TagError.prototype.setTag = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string Message = 2;
 * @return {string}
 */
proto.protocol.TagError.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.TagError} returns this
 */
proto.protocol.TagError.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protocol.VemssInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.protocol.VemssInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protocol.VemssInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.VemssInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    domain: jspb.Message.getFieldWithDefault(msg, 1, ""),
    port: jspb.Message.getFieldWithDefault(msg, 2, 0),
    net: jspb.Message.getFieldWithDefault(msg, 3, ""),
    clientid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    security: jspb.Message.getFieldWithDefault(msg, 5, ""),
    type: jspb.Message.getFieldWithDefault(msg, 6, ""),
    servername: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protocol.VemssInfo}
 */
proto.protocol.VemssInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protocol.VemssInfo;
  return proto.protocol.VemssInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protocol.VemssInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protocol.VemssInfo}
 */
proto.protocol.VemssInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomain(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPort(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNet(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecurity(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setServername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protocol.VemssInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protocol.VemssInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protocol.VemssInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.VemssInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDomain();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPort();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getNet();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getClientid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSecurity();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getServername();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string Domain = 1;
 * @return {string}
 */
proto.protocol.VemssInfo.prototype.getDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.VemssInfo} returns this
 */
proto.protocol.VemssInfo.prototype.setDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 Port = 2;
 * @return {number}
 */
proto.protocol.VemssInfo.prototype.getPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.VemssInfo} returns this
 */
proto.protocol.VemssInfo.prototype.setPort = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string Net = 3;
 * @return {string}
 */
proto.protocol.VemssInfo.prototype.getNet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.VemssInfo} returns this
 */
proto.protocol.VemssInfo.prototype.setNet = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string ClientId = 4;
 * @return {string}
 */
proto.protocol.VemssInfo.prototype.getClientid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.VemssInfo} returns this
 */
proto.protocol.VemssInfo.prototype.setClientid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string Security = 5;
 * @return {string}
 */
proto.protocol.VemssInfo.prototype.getSecurity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.VemssInfo} returns this
 */
proto.protocol.VemssInfo.prototype.setSecurity = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string Type = 6;
 * @return {string}
 */
proto.protocol.VemssInfo.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.VemssInfo} returns this
 */
proto.protocol.VemssInfo.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string ServerName = 7;
 * @return {string}
 */
proto.protocol.VemssInfo.prototype.getServername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.VemssInfo} returns this
 */
proto.protocol.VemssInfo.prototype.setServername = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protocol.QAImage.prototype.toObject = function(opt_includeInstance) {
  return proto.protocol.QAImage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protocol.QAImage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.QAImage.toObject = function(includeInstance, msg) {
  var f, obj = {
    imgname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    imgurl: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protocol.QAImage}
 */
proto.protocol.QAImage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protocol.QAImage;
  return proto.protocol.QAImage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protocol.QAImage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protocol.QAImage}
 */
proto.protocol.QAImage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setImgname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setImgurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protocol.QAImage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protocol.QAImage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protocol.QAImage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.QAImage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImgname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImgurl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string ImgName = 1;
 * @return {string}
 */
proto.protocol.QAImage.prototype.getImgname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.QAImage} returns this
 */
proto.protocol.QAImage.prototype.setImgname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ImgUrl = 2;
 * @return {string}
 */
proto.protocol.QAImage.prototype.getImgurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.QAImage} returns this
 */
proto.protocol.QAImage.prototype.setImgurl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protocol.InviteConfigInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.protocol.InviteConfigInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protocol.InviteConfigInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.InviteConfigInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    configname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    configvalue: jspb.Message.getFieldWithDefault(msg, 3, ""),
    configunit: jspb.Message.getFieldWithDefault(msg, 4, ""),
    configprice: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protocol.InviteConfigInfo}
 */
proto.protocol.InviteConfigInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protocol.InviteConfigInfo;
  return proto.protocol.InviteConfigInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protocol.InviteConfigInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protocol.InviteConfigInfo}
 */
proto.protocol.InviteConfigInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfigname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfigvalue(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfigunit(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfigprice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protocol.InviteConfigInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protocol.InviteConfigInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protocol.InviteConfigInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.InviteConfigInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getConfigname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getConfigvalue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getConfigunit();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getConfigprice();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional uint32 Id = 1;
 * @return {number}
 */
proto.protocol.InviteConfigInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.InviteConfigInfo} returns this
 */
proto.protocol.InviteConfigInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string ConfigName = 2;
 * @return {string}
 */
proto.protocol.InviteConfigInfo.prototype.getConfigname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.InviteConfigInfo} returns this
 */
proto.protocol.InviteConfigInfo.prototype.setConfigname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ConfigValue = 3;
 * @return {string}
 */
proto.protocol.InviteConfigInfo.prototype.getConfigvalue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.InviteConfigInfo} returns this
 */
proto.protocol.InviteConfigInfo.prototype.setConfigvalue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string ConfigUnit = 4;
 * @return {string}
 */
proto.protocol.InviteConfigInfo.prototype.getConfigunit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.InviteConfigInfo} returns this
 */
proto.protocol.InviteConfigInfo.prototype.setConfigunit = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string ConfigPrice = 5;
 * @return {string}
 */
proto.protocol.InviteConfigInfo.prototype.getConfigprice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.InviteConfigInfo} returns this
 */
proto.protocol.InviteConfigInfo.prototype.setConfigprice = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protocol.PlanSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.protocol.PlanSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protocol.PlanSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.PlanSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    cycletype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cycle: jspb.Message.getFieldWithDefault(msg, 3, 0),
    price: jspb.Message.getFieldWithDefault(msg, 4, 0),
    title: jspb.Message.getFieldWithDefault(msg, 5, ""),
    introduce: jspb.Message.getFieldWithDefault(msg, 6, ""),
    sort: jspb.Message.getFieldWithDefault(msg, 7, 0),
    gifts: jspb.Message.getFieldWithDefault(msg, 8, 0),
    setlevel: jspb.Message.getFieldWithDefault(msg, 9, 0),
    rebate: jspb.Message.getFieldWithDefault(msg, 10, 0),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedat: (f = msg.getUpdatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protocol.PlanSetting}
 */
proto.protocol.PlanSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protocol.PlanSetting;
  return proto.protocol.PlanSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protocol.PlanSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protocol.PlanSetting}
 */
proto.protocol.PlanSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCycletype(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCycle(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPrice(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setIntroduce(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSort(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setGifts(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSetlevel(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRebate(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedat(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protocol.PlanSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protocol.PlanSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protocol.PlanSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.PlanSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getCycletype();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getCycle();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIntroduce();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSort();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getGifts();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getSetlevel();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getRebate();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedat();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeUint32(
      13,
      f
    );
  }
};


/**
 * optional uint32 Id = 1;
 * @return {number}
 */
proto.protocol.PlanSetting.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.PlanSetting} returns this
 */
proto.protocol.PlanSetting.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 CycleType = 2;
 * @return {number}
 */
proto.protocol.PlanSetting.prototype.getCycletype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.PlanSetting} returns this
 */
proto.protocol.PlanSetting.prototype.setCycletype = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 Cycle = 3;
 * @return {number}
 */
proto.protocol.PlanSetting.prototype.getCycle = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.PlanSetting} returns this
 */
proto.protocol.PlanSetting.prototype.setCycle = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 Price = 4;
 * @return {number}
 */
proto.protocol.PlanSetting.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.PlanSetting} returns this
 */
proto.protocol.PlanSetting.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string Title = 5;
 * @return {string}
 */
proto.protocol.PlanSetting.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.PlanSetting} returns this
 */
proto.protocol.PlanSetting.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string Introduce = 6;
 * @return {string}
 */
proto.protocol.PlanSetting.prototype.getIntroduce = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.PlanSetting} returns this
 */
proto.protocol.PlanSetting.prototype.setIntroduce = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 Sort = 7;
 * @return {number}
 */
proto.protocol.PlanSetting.prototype.getSort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.PlanSetting} returns this
 */
proto.protocol.PlanSetting.prototype.setSort = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 Gifts = 8;
 * @return {number}
 */
proto.protocol.PlanSetting.prototype.getGifts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.PlanSetting} returns this
 */
proto.protocol.PlanSetting.prototype.setGifts = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 SetLevel = 9;
 * @return {number}
 */
proto.protocol.PlanSetting.prototype.getSetlevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.PlanSetting} returns this
 */
proto.protocol.PlanSetting.prototype.setSetlevel = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint32 Rebate = 10;
 * @return {number}
 */
proto.protocol.PlanSetting.prototype.getRebate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.PlanSetting} returns this
 */
proto.protocol.PlanSetting.prototype.setRebate = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional google.protobuf.Timestamp CreatedAt = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protocol.PlanSetting.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protocol.PlanSetting} returns this
*/
proto.protocol.PlanSetting.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protocol.PlanSetting} returns this
 */
proto.protocol.PlanSetting.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protocol.PlanSetting.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.Timestamp UpdatedAt = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protocol.PlanSetting.prototype.getUpdatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protocol.PlanSetting} returns this
*/
proto.protocol.PlanSetting.prototype.setUpdatedat = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protocol.PlanSetting} returns this
 */
proto.protocol.PlanSetting.prototype.clearUpdatedat = function() {
  return this.setUpdatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protocol.PlanSetting.prototype.hasUpdatedat = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional uint32 Status = 13;
 * @return {number}
 */
proto.protocol.PlanSetting.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.PlanSetting} returns this
 */
proto.protocol.PlanSetting.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protocol.CoinPlanSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.protocol.CoinPlanSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protocol.CoinPlanSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.CoinPlanSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    weights: jspb.Message.getFieldWithDefault(msg, 3, 0),
    icon: jspb.Message.getFieldWithDefault(msg, 4, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    gifts: jspb.Message.getFieldWithDefault(msg, 6, 0),
    price: jspb.Message.getFieldWithDefault(msg, 7, 0),
    rebate: jspb.Message.getFieldWithDefault(msg, 10, 0),
    introduce: jspb.Message.getFieldWithDefault(msg, 11, ""),
    status: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protocol.CoinPlanSetting}
 */
proto.protocol.CoinPlanSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protocol.CoinPlanSetting;
  return proto.protocol.CoinPlanSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protocol.CoinPlanSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protocol.CoinPlanSetting}
 */
proto.protocol.CoinPlanSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWeights(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAmount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setGifts(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPrice(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRebate(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setIntroduce(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protocol.CoinPlanSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protocol.CoinPlanSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protocol.CoinPlanSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.CoinPlanSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getWeights();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getGifts();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeUint64(
      7,
      f
    );
  }
  f = message.getRebate();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getIntroduce();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeUint32(
      13,
      f
    );
  }
};


/**
 * optional uint32 Id = 1;
 * @return {number}
 */
proto.protocol.CoinPlanSetting.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.CoinPlanSetting} returns this
 */
proto.protocol.CoinPlanSetting.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.protocol.CoinPlanSetting.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.CoinPlanSetting} returns this
 */
proto.protocol.CoinPlanSetting.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 Weights = 3;
 * @return {number}
 */
proto.protocol.CoinPlanSetting.prototype.getWeights = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.CoinPlanSetting} returns this
 */
proto.protocol.CoinPlanSetting.prototype.setWeights = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string Icon = 4;
 * @return {string}
 */
proto.protocol.CoinPlanSetting.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.CoinPlanSetting} returns this
 */
proto.protocol.CoinPlanSetting.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint64 Amount = 5;
 * @return {number}
 */
proto.protocol.CoinPlanSetting.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.CoinPlanSetting} returns this
 */
proto.protocol.CoinPlanSetting.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint64 Gifts = 6;
 * @return {number}
 */
proto.protocol.CoinPlanSetting.prototype.getGifts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.CoinPlanSetting} returns this
 */
proto.protocol.CoinPlanSetting.prototype.setGifts = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint64 Price = 7;
 * @return {number}
 */
proto.protocol.CoinPlanSetting.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.CoinPlanSetting} returns this
 */
proto.protocol.CoinPlanSetting.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 Rebate = 10;
 * @return {number}
 */
proto.protocol.CoinPlanSetting.prototype.getRebate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.CoinPlanSetting} returns this
 */
proto.protocol.CoinPlanSetting.prototype.setRebate = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string Introduce = 11;
 * @return {string}
 */
proto.protocol.CoinPlanSetting.prototype.getIntroduce = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.CoinPlanSetting} returns this
 */
proto.protocol.CoinPlanSetting.prototype.setIntroduce = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional uint32 Status = 13;
 * @return {number}
 */
proto.protocol.CoinPlanSetting.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.CoinPlanSetting} returns this
 */
proto.protocol.CoinPlanSetting.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protocol.PlanCycle.prototype.toObject = function(opt_includeInstance) {
  return proto.protocol.PlanCycle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protocol.PlanCycle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.PlanCycle.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    settingid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    starttime: (f = msg.getStarttime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endtime: (f = msg.getEndtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    totalflow: jspb.Message.getFieldWithDefault(msg, 6, 0),
    totalflowused: jspb.Message.getFieldWithDefault(msg, 7, 0),
    setting: (f = msg.getSetting()) && proto.protocol.PlanSetting.toObject(includeInstance, f),
    userinfo: (f = msg.getUserinfo()) && proto.protocol.UserInfo.toObject(includeInstance, f),
    planuser: (f = msg.getPlanuser()) && proto.protocol.PlanUser.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protocol.PlanCycle}
 */
proto.protocol.PlanCycle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protocol.PlanCycle;
  return proto.protocol.PlanCycle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protocol.PlanCycle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protocol.PlanCycle}
 */
proto.protocol.PlanCycle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSettingid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserid(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStarttime(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndtime(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalflow(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalflowused(value);
      break;
    case 8:
      var value = new proto.protocol.PlanSetting;
      reader.readMessage(value,proto.protocol.PlanSetting.deserializeBinaryFromReader);
      msg.setSetting(value);
      break;
    case 9:
      var value = new proto.protocol.UserInfo;
      reader.readMessage(value,proto.protocol.UserInfo.deserializeBinaryFromReader);
      msg.setUserinfo(value);
      break;
    case 10:
      var value = new proto.protocol.PlanUser;
      reader.readMessage(value,proto.protocol.PlanUser.deserializeBinaryFromReader);
      msg.setPlanuser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protocol.PlanCycle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protocol.PlanCycle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protocol.PlanCycle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.PlanCycle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getSettingid();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getStarttime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndtime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTotalflow();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
  f = message.getTotalflowused();
  if (f !== 0) {
    writer.writeUint64(
      7,
      f
    );
  }
  f = message.getSetting();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.protocol.PlanSetting.serializeBinaryToWriter
    );
  }
  f = message.getUserinfo();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.protocol.UserInfo.serializeBinaryToWriter
    );
  }
  f = message.getPlanuser();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.protocol.PlanUser.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 Id = 1;
 * @return {number}
 */
proto.protocol.PlanCycle.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.PlanCycle} returns this
 */
proto.protocol.PlanCycle.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 SettingId = 2;
 * @return {number}
 */
proto.protocol.PlanCycle.prototype.getSettingid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.PlanCycle} returns this
 */
proto.protocol.PlanCycle.prototype.setSettingid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 UserId = 3;
 * @return {number}
 */
proto.protocol.PlanCycle.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.PlanCycle} returns this
 */
proto.protocol.PlanCycle.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp StartTime = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protocol.PlanCycle.prototype.getStarttime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protocol.PlanCycle} returns this
*/
proto.protocol.PlanCycle.prototype.setStarttime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protocol.PlanCycle} returns this
 */
proto.protocol.PlanCycle.prototype.clearStarttime = function() {
  return this.setStarttime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protocol.PlanCycle.prototype.hasStarttime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp EndTime = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protocol.PlanCycle.prototype.getEndtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protocol.PlanCycle} returns this
*/
proto.protocol.PlanCycle.prototype.setEndtime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protocol.PlanCycle} returns this
 */
proto.protocol.PlanCycle.prototype.clearEndtime = function() {
  return this.setEndtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protocol.PlanCycle.prototype.hasEndtime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional uint64 TotalFlow = 6;
 * @return {number}
 */
proto.protocol.PlanCycle.prototype.getTotalflow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.PlanCycle} returns this
 */
proto.protocol.PlanCycle.prototype.setTotalflow = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint64 TotalFlowUsed = 7;
 * @return {number}
 */
proto.protocol.PlanCycle.prototype.getTotalflowused = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.PlanCycle} returns this
 */
proto.protocol.PlanCycle.prototype.setTotalflowused = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional PlanSetting Setting = 8;
 * @return {?proto.protocol.PlanSetting}
 */
proto.protocol.PlanCycle.prototype.getSetting = function() {
  return /** @type{?proto.protocol.PlanSetting} */ (
    jspb.Message.getWrapperField(this, proto.protocol.PlanSetting, 8));
};


/**
 * @param {?proto.protocol.PlanSetting|undefined} value
 * @return {!proto.protocol.PlanCycle} returns this
*/
proto.protocol.PlanCycle.prototype.setSetting = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protocol.PlanCycle} returns this
 */
proto.protocol.PlanCycle.prototype.clearSetting = function() {
  return this.setSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protocol.PlanCycle.prototype.hasSetting = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional UserInfo UserInfo = 9;
 * @return {?proto.protocol.UserInfo}
 */
proto.protocol.PlanCycle.prototype.getUserinfo = function() {
  return /** @type{?proto.protocol.UserInfo} */ (
    jspb.Message.getWrapperField(this, proto.protocol.UserInfo, 9));
};


/**
 * @param {?proto.protocol.UserInfo|undefined} value
 * @return {!proto.protocol.PlanCycle} returns this
*/
proto.protocol.PlanCycle.prototype.setUserinfo = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protocol.PlanCycle} returns this
 */
proto.protocol.PlanCycle.prototype.clearUserinfo = function() {
  return this.setUserinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protocol.PlanCycle.prototype.hasUserinfo = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional PlanUser PlanUser = 10;
 * @return {?proto.protocol.PlanUser}
 */
proto.protocol.PlanCycle.prototype.getPlanuser = function() {
  return /** @type{?proto.protocol.PlanUser} */ (
    jspb.Message.getWrapperField(this, proto.protocol.PlanUser, 10));
};


/**
 * @param {?proto.protocol.PlanUser|undefined} value
 * @return {!proto.protocol.PlanCycle} returns this
*/
proto.protocol.PlanCycle.prototype.setPlanuser = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protocol.PlanCycle} returns this
 */
proto.protocol.PlanCycle.prototype.clearPlanuser = function() {
  return this.setPlanuser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protocol.PlanCycle.prototype.hasPlanuser = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protocol.PlanUser.prototype.toObject = function(opt_includeInstance) {
  return proto.protocol.PlanUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protocol.PlanUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.PlanUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    settingid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    starttime: (f = msg.getStarttime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endtime: (f = msg.getEndtime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    planlevel: jspb.Message.getFieldWithDefault(msg, 6, 0),
    status: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protocol.PlanUser}
 */
proto.protocol.PlanUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protocol.PlanUser;
  return proto.protocol.PlanUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protocol.PlanUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protocol.PlanUser}
 */
proto.protocol.PlanUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSettingid(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStarttime(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndtime(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPlanlevel(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protocol.PlanUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protocol.PlanUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protocol.PlanUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.PlanUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getSettingid();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getStarttime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndtime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPlanlevel();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
};


/**
 * optional uint32 Id = 1;
 * @return {number}
 */
proto.protocol.PlanUser.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.PlanUser} returns this
 */
proto.protocol.PlanUser.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 UserId = 2;
 * @return {number}
 */
proto.protocol.PlanUser.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.PlanUser} returns this
 */
proto.protocol.PlanUser.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 SettingId = 3;
 * @return {number}
 */
proto.protocol.PlanUser.prototype.getSettingid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.PlanUser} returns this
 */
proto.protocol.PlanUser.prototype.setSettingid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp StartTime = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protocol.PlanUser.prototype.getStarttime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protocol.PlanUser} returns this
*/
proto.protocol.PlanUser.prototype.setStarttime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protocol.PlanUser} returns this
 */
proto.protocol.PlanUser.prototype.clearStarttime = function() {
  return this.setStarttime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protocol.PlanUser.prototype.hasStarttime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp EndTime = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protocol.PlanUser.prototype.getEndtime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protocol.PlanUser} returns this
*/
proto.protocol.PlanUser.prototype.setEndtime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protocol.PlanUser} returns this
 */
proto.protocol.PlanUser.prototype.clearEndtime = function() {
  return this.setEndtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protocol.PlanUser.prototype.hasEndtime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional uint32 PlanLevel = 6;
 * @return {number}
 */
proto.protocol.PlanUser.prototype.getPlanlevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.PlanUser} returns this
 */
proto.protocol.PlanUser.prototype.setPlanlevel = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 Status = 7;
 * @return {number}
 */
proto.protocol.PlanUser.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.PlanUser} returns this
 */
proto.protocol.PlanUser.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protocol.AppVersion.prototype.toObject = function(opt_includeInstance) {
  return proto.protocol.AppVersion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protocol.AppVersion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.AppVersion.toObject = function(includeInstance, msg) {
  var f, obj = {
    versioncode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    versionname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    androiddownloadurl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    iosdownloadurl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    intro: jspb.Message.getFieldWithDefault(msg, 5, ""),
    forceupdate: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protocol.AppVersion}
 */
proto.protocol.AppVersion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protocol.AppVersion;
  return proto.protocol.AppVersion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protocol.AppVersion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protocol.AppVersion}
 */
proto.protocol.AppVersion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVersioncode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersionname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAndroiddownloadurl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setIosdownloadurl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setIntro(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForceupdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protocol.AppVersion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protocol.AppVersion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protocol.AppVersion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.AppVersion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersioncode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getVersionname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAndroiddownloadurl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIosdownloadurl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIntro();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getForceupdate();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional int32 VersionCode = 1;
 * @return {number}
 */
proto.protocol.AppVersion.prototype.getVersioncode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.AppVersion} returns this
 */
proto.protocol.AppVersion.prototype.setVersioncode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string VersionName = 2;
 * @return {string}
 */
proto.protocol.AppVersion.prototype.getVersionname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.AppVersion} returns this
 */
proto.protocol.AppVersion.prototype.setVersionname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string AndroidDownloadUrl = 3;
 * @return {string}
 */
proto.protocol.AppVersion.prototype.getAndroiddownloadurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.AppVersion} returns this
 */
proto.protocol.AppVersion.prototype.setAndroiddownloadurl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string IOSDownloadUrl = 4;
 * @return {string}
 */
proto.protocol.AppVersion.prototype.getIosdownloadurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.AppVersion} returns this
 */
proto.protocol.AppVersion.prototype.setIosdownloadurl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string Intro = 5;
 * @return {string}
 */
proto.protocol.AppVersion.prototype.getIntro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.AppVersion} returns this
 */
proto.protocol.AppVersion.prototype.setIntro = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool ForceUpdate = 6;
 * @return {boolean}
 */
proto.protocol.AppVersion.prototype.getForceupdate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.protocol.AppVersion} returns this
 */
proto.protocol.AppVersion.prototype.setForceupdate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protocol.Setting.prototype.toObject = function(opt_includeInstance) {
  return proto.protocol.Setting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protocol.Setting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.Setting.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protocol.Setting}
 */
proto.protocol.Setting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protocol.Setting;
  return proto.protocol.Setting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protocol.Setting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protocol.Setting}
 */
proto.protocol.Setting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protocol.Setting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protocol.Setting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protocol.Setting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.Setting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string Key = 1;
 * @return {string}
 */
proto.protocol.Setting.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.Setting} returns this
 */
proto.protocol.Setting.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Value = 2;
 * @return {string}
 */
proto.protocol.Setting.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.Setting} returns this
 */
proto.protocol.Setting.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protocol.OrderCoin.prototype.toObject = function(opt_includeInstance) {
  return proto.protocol.OrderCoin.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protocol.OrderCoin} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.OrderCoin.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    beforecoin: jspb.Message.getFieldWithDefault(msg, 3, 0),
    changecoin: jspb.Message.getFieldWithDefault(msg, 4, 0),
    aftercoin: jspb.Message.getFieldWithDefault(msg, 5, 0),
    type: jspb.Message.getFieldWithDefault(msg, 6, 0),
    status: jspb.Message.getFieldWithDefault(msg, 7, 0),
    ordertype: jspb.Message.getFieldWithDefault(msg, 8, 0),
    orderid: jspb.Message.getFieldWithDefault(msg, 9, ""),
    orderprice: jspb.Message.getFieldWithDefault(msg, 10, 0),
    remark: jspb.Message.getFieldWithDefault(msg, 11, ""),
    createat: (f = msg.getCreateat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updateat: (f = msg.getUpdateat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    settingid: jspb.Message.getFieldWithDefault(msg, 14, 0),
    gifts: jspb.Message.getFieldWithDefault(msg, 15, 0),
    numid: jspb.Message.getFieldWithDefault(msg, 16, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protocol.OrderCoin}
 */
proto.protocol.OrderCoin.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protocol.OrderCoin;
  return proto.protocol.OrderCoin.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protocol.OrderCoin} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protocol.OrderCoin}
 */
proto.protocol.OrderCoin.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBeforecoin(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setChangecoin(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAftercoin(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setType(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStatus(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOrdertype(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderid(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderprice(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemark(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateat(value);
      break;
    case 13:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateat(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSettingid(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setGifts(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protocol.OrderCoin.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protocol.OrderCoin.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protocol.OrderCoin} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.OrderCoin.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getBeforecoin();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getChangecoin();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getAftercoin();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getType();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getOrdertype();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getOrderid();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getOrderprice();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getRemark();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCreateat();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdateat();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSettingid();
  if (f !== 0) {
    writer.writeUint32(
      14,
      f
    );
  }
  f = message.getGifts();
  if (f !== 0) {
    writer.writeUint32(
      15,
      f
    );
  }
  f = message.getNumid();
  if (f !== 0) {
    writer.writeUint32(
      16,
      f
    );
  }
};


/**
 * optional uint32 Id = 1;
 * @return {number}
 */
proto.protocol.OrderCoin.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.OrderCoin} returns this
 */
proto.protocol.OrderCoin.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 UserId = 2;
 * @return {number}
 */
proto.protocol.OrderCoin.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.OrderCoin} returns this
 */
proto.protocol.OrderCoin.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 BeforeCoin = 3;
 * @return {number}
 */
proto.protocol.OrderCoin.prototype.getBeforecoin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.OrderCoin} returns this
 */
proto.protocol.OrderCoin.prototype.setBeforecoin = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 ChangeCoin = 4;
 * @return {number}
 */
proto.protocol.OrderCoin.prototype.getChangecoin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.OrderCoin} returns this
 */
proto.protocol.OrderCoin.prototype.setChangecoin = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 AfterCoin = 5;
 * @return {number}
 */
proto.protocol.OrderCoin.prototype.getAftercoin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.OrderCoin} returns this
 */
proto.protocol.OrderCoin.prototype.setAftercoin = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 Type = 6;
 * @return {number}
 */
proto.protocol.OrderCoin.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.OrderCoin} returns this
 */
proto.protocol.OrderCoin.prototype.setType = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 Status = 7;
 * @return {number}
 */
proto.protocol.OrderCoin.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.OrderCoin} returns this
 */
proto.protocol.OrderCoin.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 OrderType = 8;
 * @return {number}
 */
proto.protocol.OrderCoin.prototype.getOrdertype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.OrderCoin} returns this
 */
proto.protocol.OrderCoin.prototype.setOrdertype = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string OrderId = 9;
 * @return {string}
 */
proto.protocol.OrderCoin.prototype.getOrderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.OrderCoin} returns this
 */
proto.protocol.OrderCoin.prototype.setOrderid = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int64 OrderPrice = 10;
 * @return {number}
 */
proto.protocol.OrderCoin.prototype.getOrderprice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.OrderCoin} returns this
 */
proto.protocol.OrderCoin.prototype.setOrderprice = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string Remark = 11;
 * @return {string}
 */
proto.protocol.OrderCoin.prototype.getRemark = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.OrderCoin} returns this
 */
proto.protocol.OrderCoin.prototype.setRemark = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional google.protobuf.Timestamp CreateAt = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protocol.OrderCoin.prototype.getCreateat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protocol.OrderCoin} returns this
*/
proto.protocol.OrderCoin.prototype.setCreateat = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protocol.OrderCoin} returns this
 */
proto.protocol.OrderCoin.prototype.clearCreateat = function() {
  return this.setCreateat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protocol.OrderCoin.prototype.hasCreateat = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.Timestamp UpdateAt = 13;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protocol.OrderCoin.prototype.getUpdateat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 13));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protocol.OrderCoin} returns this
*/
proto.protocol.OrderCoin.prototype.setUpdateat = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protocol.OrderCoin} returns this
 */
proto.protocol.OrderCoin.prototype.clearUpdateat = function() {
  return this.setUpdateat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protocol.OrderCoin.prototype.hasUpdateat = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional uint32 SettingId = 14;
 * @return {number}
 */
proto.protocol.OrderCoin.prototype.getSettingid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.OrderCoin} returns this
 */
proto.protocol.OrderCoin.prototype.setSettingid = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional uint32 Gifts = 15;
 * @return {number}
 */
proto.protocol.OrderCoin.prototype.getGifts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.OrderCoin} returns this
 */
proto.protocol.OrderCoin.prototype.setGifts = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional uint32 NumId = 16;
 * @return {number}
 */
proto.protocol.OrderCoin.prototype.getNumid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.OrderCoin} returns this
 */
proto.protocol.OrderCoin.prototype.setNumid = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protocol.OrderPlan.prototype.toObject = function(opt_includeInstance) {
  return proto.protocol.OrderPlan.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protocol.OrderPlan} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.OrderPlan.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    coinprice: jspb.Message.getFieldWithDefault(msg, 3, 0),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    planuser: (f = msg.getPlanuser()) && proto.protocol.PlanUser.toObject(includeInstance, f),
    remark: jspb.Message.getFieldWithDefault(msg, 6, ""),
    updateat: (f = msg.getUpdateat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    planlevel: jspb.Message.getFieldWithDefault(msg, 8, 0),
    numid: jspb.Message.getFieldWithDefault(msg, 9, 0),
    setting: (f = msg.getSetting()) && proto.protocol.PlanSetting.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protocol.OrderPlan}
 */
proto.protocol.OrderPlan.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protocol.OrderPlan;
  return proto.protocol.OrderPlan.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protocol.OrderPlan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protocol.OrderPlan}
 */
proto.protocol.OrderPlan.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCoinprice(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setType(value);
      break;
    case 5:
      var value = new proto.protocol.PlanUser;
      reader.readMessage(value,proto.protocol.PlanUser.deserializeBinaryFromReader);
      msg.setPlanuser(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemark(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateat(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPlanlevel(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumid(value);
      break;
    case 10:
      var value = new proto.protocol.PlanSetting;
      reader.readMessage(value,proto.protocol.PlanSetting.deserializeBinaryFromReader);
      msg.setSetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protocol.OrderPlan.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protocol.OrderPlan.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protocol.OrderPlan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.OrderPlan.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getCoinprice();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getPlanuser();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.protocol.PlanUser.serializeBinaryToWriter
    );
  }
  f = message.getRemark();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUpdateat();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPlanlevel();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getNumid();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getSetting();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.protocol.PlanSetting.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 Id = 1;
 * @return {number}
 */
proto.protocol.OrderPlan.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.OrderPlan} returns this
 */
proto.protocol.OrderPlan.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 UserId = 2;
 * @return {number}
 */
proto.protocol.OrderPlan.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.OrderPlan} returns this
 */
proto.protocol.OrderPlan.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 CoinPrice = 3;
 * @return {number}
 */
proto.protocol.OrderPlan.prototype.getCoinprice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.OrderPlan} returns this
 */
proto.protocol.OrderPlan.prototype.setCoinprice = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 Type = 4;
 * @return {number}
 */
proto.protocol.OrderPlan.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.OrderPlan} returns this
 */
proto.protocol.OrderPlan.prototype.setType = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional PlanUser PlanUser = 5;
 * @return {?proto.protocol.PlanUser}
 */
proto.protocol.OrderPlan.prototype.getPlanuser = function() {
  return /** @type{?proto.protocol.PlanUser} */ (
    jspb.Message.getWrapperField(this, proto.protocol.PlanUser, 5));
};


/**
 * @param {?proto.protocol.PlanUser|undefined} value
 * @return {!proto.protocol.OrderPlan} returns this
*/
proto.protocol.OrderPlan.prototype.setPlanuser = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protocol.OrderPlan} returns this
 */
proto.protocol.OrderPlan.prototype.clearPlanuser = function() {
  return this.setPlanuser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protocol.OrderPlan.prototype.hasPlanuser = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string Remark = 6;
 * @return {string}
 */
proto.protocol.OrderPlan.prototype.getRemark = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.OrderPlan} returns this
 */
proto.protocol.OrderPlan.prototype.setRemark = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp UpdateAt = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protocol.OrderPlan.prototype.getUpdateat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protocol.OrderPlan} returns this
*/
proto.protocol.OrderPlan.prototype.setUpdateat = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protocol.OrderPlan} returns this
 */
proto.protocol.OrderPlan.prototype.clearUpdateat = function() {
  return this.setUpdateat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protocol.OrderPlan.prototype.hasUpdateat = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional uint32 PlanLevel = 8;
 * @return {number}
 */
proto.protocol.OrderPlan.prototype.getPlanlevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.OrderPlan} returns this
 */
proto.protocol.OrderPlan.prototype.setPlanlevel = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 NumId = 9;
 * @return {number}
 */
proto.protocol.OrderPlan.prototype.getNumid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.OrderPlan} returns this
 */
proto.protocol.OrderPlan.prototype.setNumid = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional PlanSetting Setting = 10;
 * @return {?proto.protocol.PlanSetting}
 */
proto.protocol.OrderPlan.prototype.getSetting = function() {
  return /** @type{?proto.protocol.PlanSetting} */ (
    jspb.Message.getWrapperField(this, proto.protocol.PlanSetting, 10));
};


/**
 * @param {?proto.protocol.PlanSetting|undefined} value
 * @return {!proto.protocol.OrderPlan} returns this
*/
proto.protocol.OrderPlan.prototype.setSetting = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protocol.OrderPlan} returns this
 */
proto.protocol.OrderPlan.prototype.clearSetting = function() {
  return this.setSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protocol.OrderPlan.prototype.hasSetting = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protocol.PayChannel.prototype.toObject = function(opt_includeInstance) {
  return proto.protocol.PayChannel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protocol.PayChannel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.PayChannel.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    blockchain: jspb.Message.getFieldWithDefault(msg, 3, ""),
    transtype: jspb.Message.getFieldWithDefault(msg, 4, ""),
    contractaddress: jspb.Message.getFieldWithDefault(msg, 5, ""),
    payeeaddress: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protocol.PayChannel}
 */
proto.protocol.PayChannel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protocol.PayChannel;
  return proto.protocol.PayChannel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protocol.PayChannel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protocol.PayChannel}
 */
proto.protocol.PayChannel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBlockchain(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTranstype(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractaddress(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayeeaddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protocol.PayChannel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protocol.PayChannel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protocol.PayChannel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.PayChannel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBlockchain();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTranstype();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getContractaddress();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPayeeaddress();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional uint32 Id = 1;
 * @return {number}
 */
proto.protocol.PayChannel.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.PayChannel} returns this
 */
proto.protocol.PayChannel.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.protocol.PayChannel.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.PayChannel} returns this
 */
proto.protocol.PayChannel.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Blockchain = 3;
 * @return {string}
 */
proto.protocol.PayChannel.prototype.getBlockchain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.PayChannel} returns this
 */
proto.protocol.PayChannel.prototype.setBlockchain = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string TransType = 4;
 * @return {string}
 */
proto.protocol.PayChannel.prototype.getTranstype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.PayChannel} returns this
 */
proto.protocol.PayChannel.prototype.setTranstype = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string ContractAddress = 5;
 * @return {string}
 */
proto.protocol.PayChannel.prototype.getContractaddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.PayChannel} returns this
 */
proto.protocol.PayChannel.prototype.setContractaddress = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string PayeeAddress = 6;
 * @return {string}
 */
proto.protocol.PayChannel.prototype.getPayeeaddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.PayChannel} returns this
 */
proto.protocol.PayChannel.prototype.setPayeeaddress = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.protocol.UploadPicture.prototype.toObject = function(opt_includeInstance) {
  return proto.protocol.UploadPicture.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.protocol.UploadPicture} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.UploadPicture.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    filename: jspb.Message.getFieldWithDefault(msg, 3, ""),
    feature: jspb.Message.getFieldWithDefault(msg, 4, ""),
    status: jspb.Message.getFieldWithDefault(msg, 5, 0),
    createat: (f = msg.getCreateat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updateat: (f = msg.getUpdateat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.protocol.UploadPicture}
 */
proto.protocol.UploadPicture.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.protocol.UploadPicture;
  return proto.protocol.UploadPicture.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.protocol.UploadPicture} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.protocol.UploadPicture}
 */
proto.protocol.UploadPicture.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilename(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeature(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStatus(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateat(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.protocol.UploadPicture.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.protocol.UploadPicture.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.protocol.UploadPicture} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.protocol.UploadPicture.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getFilename();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFeature();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getCreateat();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdateat();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 Id = 1;
 * @return {number}
 */
proto.protocol.UploadPicture.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.UploadPicture} returns this
 */
proto.protocol.UploadPicture.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 UserId = 2;
 * @return {number}
 */
proto.protocol.UploadPicture.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.UploadPicture} returns this
 */
proto.protocol.UploadPicture.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string FileName = 3;
 * @return {string}
 */
proto.protocol.UploadPicture.prototype.getFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.UploadPicture} returns this
 */
proto.protocol.UploadPicture.prototype.setFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Feature = 4;
 * @return {string}
 */
proto.protocol.UploadPicture.prototype.getFeature = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.protocol.UploadPicture} returns this
 */
proto.protocol.UploadPicture.prototype.setFeature = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 Status = 5;
 * @return {number}
 */
proto.protocol.UploadPicture.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.protocol.UploadPicture} returns this
 */
proto.protocol.UploadPicture.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp CreateAt = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protocol.UploadPicture.prototype.getCreateat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protocol.UploadPicture} returns this
*/
proto.protocol.UploadPicture.prototype.setCreateat = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protocol.UploadPicture} returns this
 */
proto.protocol.UploadPicture.prototype.clearCreateat = function() {
  return this.setCreateat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protocol.UploadPicture.prototype.hasCreateat = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp UpdateAt = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.protocol.UploadPicture.prototype.getUpdateat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.protocol.UploadPicture} returns this
*/
proto.protocol.UploadPicture.prototype.setUpdateat = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.protocol.UploadPicture} returns this
 */
proto.protocol.UploadPicture.prototype.clearUpdateat = function() {
  return this.setUpdateat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.protocol.UploadPicture.prototype.hasUpdateat = function() {
  return jspb.Message.getField(this, 7) != null;
};


goog.object.extend(exports, proto.protocol);
